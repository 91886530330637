/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Plugins
import $ from 'jquery'
import Rails from 'rails-ujs/lib/assets/compiled/rails-ujs'
import 'bootstrap-sass/assets/javascripts/bootstrap'
import 'bootstrap-toggle/js/bootstrap-toggle.min'
import 'select2/dist/js/select2'
import 'jquery.scrollbar/jquery.scrollbar'
import 'datatables.net/js/jquery.dataTables'
import 'daterangepicker/daterangepicker'
import 'jquery.scrollto/jquery.scrollTo'
import 'moment/moment'
import 'jquery-base64/jquery.base64'
import swal from 'sweetalert2/dist/sweetalert2.min'
import 'metismenu/dist/metisMenu'
import Routes from '../../../assets/javascripts/routes'
import toastr from 'toastr/build/toastr.min'
import 'i18n-js/app/assets/javascripts/i18n'

// Customize JS
import '../../v1/home_orgchart'
import '../../v1/inspinia'

// Globals
Rails.start();
window.moment = moment;
window.Highcharts = require('highcharts/highstock.js');
require('highcharts/highcharts-more.js')(Highcharts);
require('highcharts/modules/solid-gauge.js')(Highcharts);
require('highcharts/modules/boost.js')(Highcharts);
require('highcharts/modules/no-data-to-display.js')(Highcharts);
window.PhotoSwipe = require('photoswipe/dist/photoswipe.js');
window.PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default.js');
window.Routes = Routes;
window.toastr = toastr;
window.swal = swal;
window.I18n = require('i18n-js');
require('v1/translations');
