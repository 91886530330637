I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "activerecord": {
    "attributes": {
      "can_labtest_upload": {
        "created_at": "Upload Time",
        "id": "Batch No.",
        "status": "Status"
      },
      "doorkeeper/application": {
        "name": "Name",
        "redirect_uri": "Redirect URI"
      },
      "location": {
        "daily_target_aggressiveness_level": "Aggressiveness Level",
        "daily_target_current_option": "Current Option",
        "daily_target_options": "Available Options",
        "forecast_system_adjustment_factor": "Forecast Adjustment Factor"
      },
      "user": {
        "activation": "Initial Login",
        "current_password": "Current Password",
        "email": "Login Email",
        "first_name": "First Name",
        "last_name": "Last Name",
        "notification_email": "Notification Email",
        "password": "Password",
        "password_confirmation": "Password Confirmation"
      },
      "user_preference": {
        "language": "Language",
        "send_abnormality_notification": "Enable Abnormality Alert",
        "send_energy_use_status_email": "Receive Energy Use Email",
        "send_notification_reminder_email": "Receive Notification Reminder Email",
        "send_office_hour_comparison_alert": "Receive Office Hour Comparison Alert",
        "send_overuse_appliance_notification": "Enable Overuse Appliance Alert",
        "send_weekly_summary_email": "Receive Weekly Summary Email"
      },
      "user_target_template": {
        "effective_on": "Effective Date"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      },
      "models": {
        "doorkeeper/application": {
          "attributes": {
            "redirect_uri": {
              "forbidden_uri": "is forbidden by the server.",
              "fragment_present": "cannot contain a fragment.",
              "invalid_uri": "must be a valid URI.",
              "relative_uri": "must be an absolute URI.",
              "secured_uri": "must be an HTTPS/SSL URI.",
              "unspecified_scheme": "must specify a scheme."
            },
            "scopes": {
              "not_match_configured": "doesn't match configured on the server."
            }
          }
        },
        "user": {
          "attributes": {
            "current_password": {
              "blank": "can't be blank"
            },
            "email": {
              "blank": "Please input email"
            },
            "notification_email": {
              "invalid": "Please input correct email format"
            },
            "password": {
              "blank": "can't be blank"
            },
            "password_confirmation": {
              "blank": "can't be blank"
            }
          }
        },
        "user_target_template": {
          "attributes": {
            "effective_on": {
              "blank": "can't be blank",
              "not_a_date": "is not a date"
            },
            "friday": {
              "blank": "can't be blank",
              "greater_than_or_equal_to": "is not positive integer",
              "not_a_number": "is not a number",
              "not_an_integer": "is not an integer"
            },
            "monday": {
              "blank": "can't be blank",
              "greater_than_or_equal_to": "is not positive integer",
              "not_a_number": "is not a number",
              "not_an_integer": "is not an integer"
            },
            "saturday": {
              "blank": "can't be blank",
              "greater_than_or_equal_to": "is not positive integer",
              "not_a_number": "is not a number",
              "not_an_integer": "is not an integer"
            },
            "sunday": {
              "blank": "can't be blank",
              "greater_than_or_equal_to": "is not positive integer",
              "not_a_number": "is not a number",
              "not_an_integer": "is not an integer"
            },
            "thursday": {
              "blank": "can't be blank",
              "greater_than_or_equal_to": "is not positive integer",
              "not_a_number": "is not a number",
              "not_an_integer": "is not an integer"
            },
            "tuesday": {
              "blank": "can't be blank",
              "greater_than_or_equal_to": "is not positive integer",
              "not_a_number": "is not a number",
              "not_an_integer": "is not an integer"
            },
            "wednesday": {
              "blank": "can't be blank",
              "greater_than_or_equal_to": "is not positive integer",
              "not_a_number": "is not a number",
              "not_an_integer": "is not an integer"
            }
          }
        }
      }
    },
    "models": {
      "user": "User"
    }
  },
  "admin": {
    "can_labtest_upload": {
      "action_fail": "Upload error. Please upload again.",
      "action_success": "File is uploaded. An email will be sent to you few minutes later about the processing."
    },
    "user": {
      "action_succeed": "%{action} user was successful"
    }
  },
  "appliance_categories": {
    "ac": "AC",
    "all": "All",
    "cooking_appliance": "Cooking Appliance",
    "fridge": "Fridge",
    "light": "Light",
    "mixed_usage": "Mixed Usage",
    "other": "Other",
    "socket": "Socket",
    "water_heater": "Water Heater"
  },
  "breadcrumbs": {
    "application": {
      "root": "Index"
    },
    "pages": {
      "pages": "Pages"
    }
  },
  "comparison": {
    "energy_tab": "Energy",
    "iaq_tab": "IAQ",
    "label": "COMPARISON",
    "oaq_tab": "OAQ",
    "peer": {
      "all_appliances": "All appliances",
      "customized_from_to": "Your usage vs your peers’ usage from %{start_time} to %{end_time}.",
      "customized_from_to_iaq": "Your IAQ vs your peers’ IAQ from %{start_time} to %{end_time}.",
      "customized_from_to_oaq": "Your OAQ vs your peers’ OAQ from %{start_time} to %{end_time}.",
      "energy_today": "Today’s energy consumption from %{start_time} to %{end_time}, updated hourly.",
      "iaq_today": "Today’s IAQ index from %{start_time} to %{end_time}, updated every 15 minutes.",
      "loading": "Loading...",
      "me": "ME",
      "not_available": "N/A",
      "oaq_today": "Today’s OAQ index from %{start_time} to %{end_time}.",
      "title": "Peer Comparison",
      "toggle": "Actual Usage"
    },
    "self": {
      "current_week_skip_reason": "Current week is skipped because the week is not completed",
      "end_date": "End Date",
      "percentage_change": "% Change",
      "start_date": "Start Date",
      "title": "Weekly Comparison",
      "week": "Week"
    },
    "ust_public": {
      "range_from_to": "Cumulative energy consumption from %{start_time} to %{end_time}. ",
      "title": "UG Hall 6 Energy Usage Comparison"
    }
  },
  "daily_target": {
    "change_fail": "Error! Daily Quota option is not saved",
    "change_succeed": "Daily Quota option is saved",
    "choose": "Choose",
    "chosen": "Chosen",
    "not_support": "Sorry, this location does not support daily quota.",
    "review": "Review",
    "system_assigned": "System Assigned",
    "system_description": "We know that setting daily quota is hard indeed. That's why Blue Sky deploys Artificial Intelligence (A.I.) model to set it for you, making your conservation plan hassle free.  You can always review the model quota at the graph tab.<br/><br/>First time user? Start with \"Save according to System Default\" to feel the power of our model prediction.<br/><br/>Want some challenge? Select one of the 3%, 5% or 8% choices below to make additional commitment on top of the A.I. quota.",
    "title": "Daily Quota",
    "user_assigned": "User Assigned",
    "user_description": "Want to master the art of setting your own daily quota instead? Click \"Choose\" here to do it the good old-fashioned way. You can set quotas that fit your daily activity and schedule."
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "date_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "daily_graph": "%b %-d",
      "default": "%Y-%m-%d",
      "iaq_weekly_summary_v1_month": "%B",
      "long": "%B %d, %Y",
      "short": "%b %d",
      "weekly_summary_v2_month": "%B"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "daterangepicker": {
    "apply_label": "Apply",
    "cancel_label": "Cancel",
    "custom_range_label": "Custom",
    "days_of_week": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "from_label": "From",
    "last_24_hours": "Last 24 Hours",
    "last_30_days": "Last 30 Days",
    "last_7_days": "Last 7 Days",
    "last_month": "Last Month",
    "month_names": [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec"
    ],
    "this_month": "Current Month",
    "to_label": "To",
    "today": "Today"
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Seconds",
      "year": "Year"
    }
  },
  "device": {
    "capability": {
      "incapable": "Your device does not support video autoplay.<br/>To enable this feature, please update iOS to the latest version."
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing."
    },
    "mailer": {
      "confirmation_instructions": {
        "subject": "Confirmation email"
      },
      "email_changed": {
        "subject": "Email Changed"
      },
      "password_change": {
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again"
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "unlocks": {
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "doorkeeper": {
    "applications": {
      "buttons": {
        "authorize": "Authorize",
        "cancel": "Cancel",
        "destroy": "Destroy",
        "edit": "Edit",
        "submit": "Submit"
      },
      "confirmations": {
        "destroy": "Are you sure?"
      },
      "edit": {
        "title": "Edit application"
      },
      "form": {
        "error": "Whoops! Check your form for possible errors"
      },
      "help": {
        "blank_redirect_uri": "Leave it blank if you configured your provider to use Client Credentials, Resource Owner Password Credentials or any other grant type that doesn't require redirect URI.",
        "confidential": "Application will be used where the client secret can be kept confidential. Native mobile apps and Single Page Apps are considered non-confidential.",
        "native_redirect_uri": "Use %{native_redirect_uri} if you want to add localhost URIs for development purposes",
        "redirect_uri": "Use one line per URI",
        "scopes": "Separate scopes with spaces. Leave blank to use the default scopes."
      },
      "index": {
        "actions": "Actions",
        "callback_url": "Callback URL",
        "confidential": "Confidential?",
        "confidentiality": {
          "no": "No",
          "yes": "Yes"
        },
        "name": "Name",
        "new": "New Application",
        "title": "Your applications"
      },
      "new": {
        "title": "New Application"
      },
      "show": {
        "actions": "Actions",
        "application_id": "Application UID",
        "callback_urls": "Callback urls",
        "confidential": "Confidential",
        "not_defined": "Not defined",
        "scopes": "Scopes",
        "secret": "Secret",
        "secret_hashed": "Secret hashed",
        "title": "Application: %{name}"
      }
    },
    "authorizations": {
      "buttons": {
        "authorize": "Authorize",
        "deny": "Deny"
      },
      "error": {
        "title": "An error has occurred"
      },
      "new": {
        "able_to": "This application will be able to",
        "prompt": "Authorize %{client_name} to use your account?",
        "title": "Authorization required"
      },
      "show": {
        "title": "Authorization code"
      }
    },
    "authorized_applications": {
      "buttons": {
        "revoke": "Revoke"
      },
      "confirmations": {
        "revoke": "Are you sure?"
      },
      "index": {
        "application": "Application",
        "created_at": "Created At",
        "date_format": "%Y-%m-%d %H:%M:%S",
        "title": "Your authorized applications"
      }
    },
    "errors": {
      "messages": {
        "access_denied": "The resource owner or authorization server denied the request.",
        "admin_authenticator_not_configured": "Access to admin panel is forbidden due to Doorkeeper.configure.admin_authenticator being unconfigured.",
        "credential_flow_not_configured": "Resource Owner Password Credentials flow failed due to Doorkeeper.configure.resource_owner_from_credentials being unconfigured.",
        "invalid_client": "Client authentication failed due to unknown client, no client authentication included, or unsupported authentication method.",
        "invalid_code_challenge_method": "The code challenge method must be plain or S256.",
        "invalid_grant": "The provided authorization grant is invalid, expired, revoked, does not match the redirection URI used in the authorization request, or was issued to another client.",
        "invalid_redirect_uri": "The requested redirect uri is malformed or doesn't match client redirect URI.",
        "invalid_request": "The request is missing a required parameter, includes an unsupported parameter value, or is otherwise malformed.",
        "invalid_scope": "The requested scope is invalid, unknown, or malformed.",
        "invalid_token": {
          "expired": "The access token expired",
          "revoked": "The access token was revoked",
          "unknown": "The access token is invalid"
        },
        "resource_owner_authenticator_not_configured": "Resource Owner find failed due to Doorkeeper.configure.resource_owner_authenticator being unconfigured.",
        "revoke": {
          "unauthorized": "You are not authorized to revoke this token"
        },
        "server_error": "The authorization server encountered an unexpected condition which prevented it from fulfilling the request.",
        "temporarily_unavailable": "The authorization server is currently unable to handle the request due to a temporary overloading or maintenance of the server.",
        "unauthorized_client": "The client is not authorized to perform this request using this method.",
        "unsupported_grant_type": "The authorization grant type is not supported by the authorization server.",
        "unsupported_response_type": "The authorization server does not support this response type."
      }
    },
    "flash": {
      "applications": {
        "create": {
          "notice": "Application created."
        },
        "destroy": {
          "notice": "Application deleted."
        },
        "update": {
          "notice": "Application updated."
        }
      },
      "authorized_applications": {
        "destroy": {
          "notice": "Application revoked."
        }
      }
    },
    "layouts": {
      "admin": {
        "nav": {
          "applications": "Applications",
          "home": "Home",
          "oauth2_provider": "OAuth2 Provider"
        },
        "title": "Doorkeeper"
      },
      "application": {
        "title": "OAuth authorization required"
      }
    },
    "pre_authorization": {
      "status": "Pre-authorization"
    }
  },
  "email": {
    "change_how_you_receive": "Want to change how you receive this email? Go to profile page in the dashboard.",
    "download_mobile_apps": "Download the Blue Sky Mobile App",
    "energy_use_status": {
      "at": "At",
      "click_below": "Let's click below button!",
      "go_to_dashboard": "Go To Dashboard",
      "know_more": "Want to know more?",
      "of_daily_target": "of Daily Quota",
      "subject": "Blue Sky - Energy Use"
    },
    "iaq_weekly_summary": {
      "v1": {
        "24_hours": "24 Hours",
        "average_level": "Average Level",
        "data_incomplete": {
          "html": "There is a network issue connecting to <strong> %{location_name}</strong>'s IAQ sensors.<br/>Please contact your admin or email <a href='mailto:connect@hibluesky.co'>connect@hibluesky.co</a> for support.",
          "title": "NOTE: INCOMPLETE DATA"
        },
        "distribution_graph_title": "Score Level Distribution",
        "faq": {
          "html": "<a href='https://hibluesky.helpsite.com/categories/8889-indoor-air-quality-faq'>Click here for FAQ</a>"
        },
        "footer": {
          "question_comment": "Questions or comments? Send an email to",
          "unsubscribe": "If you want to unsubscribe, please edit your notification preferences"
        },
        "heat_map_title": "IAQ Score Heat Map",
        "launch": {
          "html": "This is the IAQ weekly summary which presents the average IAQ score and average level of the 5 IAQ parameters in %{location}. With an IAQ score heat map, we hope the summary improves your data visualization experience. Let us know what you think of this summary by emailing <a href='mailto:connect@hibluesky.co'>connect@hibluesky.co</a> and we hope you like it!",
          "title": "IAQ WEEKLY SUMMARY"
        },
        "missing_remark": "(Blank period represents missing data)",
        "operating_hours": "Operating Hours",
        "overall": "Overall",
        "see_iaq_trends": "SEE IAQ TRENDS",
        "subject": "Blue Sky - IAQ Weekly Summary",
        "title": "IAQ Weekly Summary",
        "whole_day": "Whole Day"
      }
    },
    "notification_reminder": {
      "and_more": "and more...",
      "feedback_message": "Any feedback or questions can be sent to ",
      "response_message": "We'll get back to you as soon as possible.",
      "see_trends": "SEE TRENDS",
      "subject": "Blue Sky - Multiple energy alerts",
      "unsubscribe_click_here": "notification preferences",
      "unsubscribe_message": "If you want to unsubscribe, please edit "
    },
    "sent_from": "This email is sent from",
    "weekly_summary": {
      "v1": {
        "action": {
          "rationale_with_percentage": "Your %{appliance} has increased by %{percentage}%. Doing this might help!",
          "rationale_without_percentage": "Your %{appliance} consumption was a bit high. Doing this might help!",
          "tips": "Turn off Main AC Power before the long holiday",
          "title": "Action Tips"
        },
        "graph": {
          "decrease": "Saved",
          "increase": "Used More",
          "last_week": "One Week Ago",
          "title": "Daily Energy Use",
          "two_weeks_ago": "Two Weeks Ago",
          "xaxis": [
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat",
            "Sun"
          ]
        },
        "greeting": {
          "checkout": "Check out one week ago's energy usage compared to two weeks ago",
          "hi": "Hi %{name}, what a week!"
        },
        "metric": {
          "fun_fact": "Fun Fact",
          "fun_fact_description": "trips to the Airport from TST in an electric vehicle",
          "fun_fact_unit": "trips",
          "total_energy": "Total Energy",
          "total_energy_description": "combined energy use for last week in your locations"
        },
        "remark": {
          "warning": "The data represented above may be incomplete due to unforeseen technical difficulties."
        },
        "subject": "Blue Sky - Weekly Summary"
      },
      "v2": {
        "data_incomplete": {
          "html": "Smart meters network or budget forecast was unstable at <strong> %{location_name}</strong>.<br/>Data shown below may not be the most accurate.<br/>Please contact your admin or email <a href='mailto:connect@hibluesky.co'>connect@hibluesky.co</a> for support.",
          "title": "NOTE: INCOMPLETE DATA"
        },
        "energy_consumption": {
          "iphone": "of iPhone",
          "iphone_unit": "charges",
          "tesla": "Distance travelled by electric vehicle",
          "title": "Last Week's Energy Consumption"
        },
        "energy_distribution": {
          "title": "Energy Distribution"
        },
        "footer": {
          "question_comment": "Questions or comments? Send an email to",
          "unsubscribe": "If you want to unsubscribe, please edit your notification preferences"
        },
        "graph": {
          "distribution_graph": {
            "x_label": [
              "2 WEEKS AGO",
              "LAST WEEK"
            ]
          },
          "performance_graph": {
            "x_label": [
              "M",
              "T",
              "W",
              "T",
              "F",
              "S",
              "S"
            ]
          }
        },
        "launch": {
          "html": "This is a new version of the weekly summary with more information and improved visualizations. It presents data from your default location, %{location}, instead of aggregating the energy use from all of your locations. Let us know what you think of this new version by emailing <a href='mailto:connect@hibluesky.co'>connect@hibluesky.co</a> and we hope you like it!",
          "title": "NEW WEEKLY SUMMARY"
        },
        "performance": {
          "cheer_up": {
            "do_better": "Try doing better next week!",
            "keep_up": "Keep up the good work!"
          },
          "days_within_budget": "You were within your energy budget %{days} out of 7 days",
          "description": {
            "exceed_budget": "Above weekly budget %{percentage}",
            "within_budget": "Below weekly budget by %{percentage}"
          },
          "foot_note": "* The budget is calculated based on Blue Sky’s AI prediction model. It accounts for historical energy usage, weather, day of the week, public holidays and other factors.",
          "legend": {
            "above_budget": "Above Budget",
            "below_budget": "Below Budget",
            "budget": "Budget"
          },
          "title": "Last Week's Performance"
        },
        "ranking": {
          "description": "better than %{rank_percentage} of users",
          "foot_note": "* The ranking is determined by comparing each location's energy usage per square meter.",
          "position": "%{rank} out of %{total}",
          "position_change": {
            "decrease": {
              "one": "Decrease of 1 place compared to previous week",
              "other": "Decrease of %{count} places compared to previous week"
            },
            "increase": {
              "one": "Increase of 1 place compared to previous week",
              "other": "Increase of %{count} places compared to previous week"
            },
            "no_change": "No change of place compared to previous week"
          },
          "title": "Your Ranking"
        },
        "see_energy_trends": "SEE ENERGY TRENDS",
        "subject": "Weekly Energy Summary",
        "tips": {
          "description": "For appliances at your location that use the most energy",
          "title": "Energy Saving Tips"
        },
        "title": "Weekly Summary"
      }
    },
    "welcome": {
      "access_web_version": "Access the Web version",
      "greeting": {
        "checkout": "Blue Sky is partnering with your location to help save energy and money by optimizing your environment's collective electricity usage.",
        "hi": "Hi %{name}"
      },
      "main_body": {
        "credentials": {
          "login": "Login",
          "password": "Password"
        },
        "login_description": "You can check the dashboard anytime using the following",
        "video_description": "Check out how our system is working for you"
      },
      "subject": "Blue Sky - Great to Have You"
    }
  },
  "enumerize": {
    "location": {
      "daily_target_aggressiveness_level": {
        "aggressive": "Save Aggressively (Default minus 8%)",
        "conservative": "Save Conservatively (Default minus 3%)",
        "default": "Save according to System Default",
        "normal": "Save Normally (Default minus 5%)"
      }
    },
    "user": {
      "user_type": {
        "public": "Public Display"
      }
    },
    "user_preference": {
      "language": {
        "en": "English 英文",
        "zh-TW": "Traditional Chinese 繁體中文"
      }
    }
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "carrierwave_download_error": "could not be downloaded",
      "carrierwave_integrity_error": "is not of an allowed file type",
      "carrierwave_processing_error": "failed to be processed",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "content_type_blacklist_error": "You are not allowed to upload %{content_type} files",
      "content_type_whitelist_error": "You are not allowed to upload %{content_type} files, allowed types: %{allowed_types}",
      "date_after": "must be after %{date}",
      "date_after_or_equal_to": "must be after or equal to %{date}",
      "date_before": "must be before %{date}",
      "date_before_or_equal_to": "must be before or equal to %{date}",
      "date_equal_to": "must be equal to %{date}",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "extension_blacklist_error": "You are not allowed to upload %{extension} files, prohibited types: %{prohibited_types}",
      "extension_whitelist_error": "You are not allowed to upload %{extension} files, allowed types: %{allowed_types}",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "max_size_error": "File size should be less than %{max_size}",
      "min_size_error": "File size should be greater than %{min_size}",
      "mini_magick_processing_error": "Failed to manipulate with MiniMagick, maybe it is not an image? Original Error: %{e}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_date": "is not a date",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "rmagick_processing_error": "Failed to manipulate with rmagick, maybe it is not an image?",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "first_login": {
    "hello": "Hello!",
    "intro": "Start personalizing your account!",
    "intro_popup": {
      "confirm_button": "Let's save energy!",
      "next_button": "Next"
    },
    "notification_email_hint": "This will be your login name and password recovery email going forward. Optional energy tips, fun facts and Blue Sky notifications will be sent here too.",
    "password_confirmation_label": "Confirm New Password",
    "password_confirmation_placeholder": "Enter the password again",
    "password_label": "New Password",
    "password_placeholder": "Leave blank if want to keep the original password",
    "skip_link": "Skip",
    "skip_popup": {
      "cancel_button": "Cancel",
      "confirm_button": "Skip for now",
      "message1": "By skipping, you might miss out on:<br/>1. Energy alerts & reports<br/>2. Opportunities to join fun events<br/>3. Collaborations with peers<br/>",
      "message2": "OK. If you change your mind, you can update your personal information later on PROFILE page.",
      "title": "Are you sure?"
    },
    "submit_popup": {
      "cancel_button": "Fill now",
      "confirm_button": "Continue Update",
      "message": "Notification Email is blank. This is for receiving tips on your usage, notices of fun events, and for password reset. We will not share it with 3rd party.",
      "title": "Continue?"
    },
    "update_button": "Update"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "general": {
    "15_min_up_to": "Updated every 15 minutes, as of %{time}",
    "hourly_up_to": "Updated hourly, as of %{time}"
  },
  "grape": {
    "errors": {
      "format": "%{attributes} %{message}",
      "messages": {
        "all_or_none": "provide all or none of parameters",
        "at_least_one": "are missing, at least one parameter must be provided",
        "blank": "is empty",
        "coerce": "is invalid",
        "exactly_one": "are missing, exactly one parameter must be provided",
        "except_values": "has a value not allowed",
        "incompatible_option_values": "%{option1}: %{value1} is incompatible with %{option2}: %{value2}",
        "invalid_accept_header": {
          "problem": "Invalid accept header",
          "resolution": "%{message}"
        },
        "invalid_formatter": "cannot convert %{klass} to %{to_format}",
        "invalid_message_body": {
          "problem": "message body does not match declared format",
          "resolution": "when specifying %{body_format} as content-type, you must pass valid %{body_format} in the request's 'body' "
        },
        "invalid_response": "Invalid response",
        "invalid_version_header": {
          "problem": "Invalid version header",
          "resolution": "%{message}"
        },
        "invalid_versioner_option": {
          "problem": "Unknown :using for versioner: %{strategy}",
          "resolution": "available strategy for :using is :path, :header, :accept_version_header, :param"
        },
        "invalid_with_option_for_represent": {
          "problem": "You must specify an entity class in the :with option.",
          "resolution": "eg: represent User, :with => Entity::User"
        },
        "missing_group_type": "group type is required",
        "missing_mime_type": {
          "problem": "missing mime type for %{new_format}",
          "resolution": "you can choose existing mime type from Grape::ContentTypes::CONTENT_TYPES or add your own with content_type :%{new_format}, 'application/%{new_format}' "
        },
        "missing_option": "You must specify :%{option} options.",
        "missing_vendor_option": {
          "problem": "missing :vendor option.",
          "resolution": "eg: version 'v1', using: :header, vendor: 'twitter'",
          "summary": "when version using header, you must specify :vendor option. "
        },
        "mutual_exclusion": "are mutually exclusive",
        "presence": "is missing",
        "regexp": "is invalid",
        "same_as": "is not the same as %{parameter}",
        "unknown_options": "unknown options: %{options}",
        "unknown_parameter": "unknown parameter: %{param}",
        "unknown_validator": "unknown validator: %{validator_type}",
        "unsupported_group_type": "group type must be Array, Hash, JSON or Array[JSON]",
        "values": "does not have a valid value"
      }
    }
  },
  "graph": {
    "all_locations": "All Locations",
    "appliance": "Appliance",
    "daily": "Day",
    "distribution": {
      "chart_title": "Energy Consumption by Appliance",
      "last_24_hours": "Last 24 Hours"
    },
    "distribution_graph": "Distribution Graph",
    "every_minute": "Minute",
    "hourly": "Hour",
    "iaq": {
      "co2": "CO2",
      "co2_y_axis": "CO2 ppm",
      "cold": "Cold",
      "dry": "Dry",
      "fair": "Fair",
      "healthy": "Healthy",
      "hot": "Hot",
      "humid": "Humidity",
      "humid_y_axis": "Humidity %",
      "iaq_update": "IAQ data updated every 15 minutes",
      "moderate": "Moderate",
      "no_data": "No Data",
      "optimal": "Optimal",
      "pm25": "PM2.5",
      "pm25_y_axis": "PM2.5 μg/m3",
      "poor": "Poor",
      "score": "IAQ Score",
      "score_y_axis": "Score",
      "temp": "Temperature",
      "temp_y_axis": "Temp °C",
      "unhealthy": "Unhealthy",
      "voc": "VOC",
      "voc_y_axis": "VOC ppb",
      "wet": "Wet"
    },
    "iaq_graph": "IAQ Graph",
    "interval": "Interval",
    "oaq": {
      "PM25": "PM2.5",
      "co": "CO",
      "co_unit": "ppm",
      "co_y_axis": "CO ppm",
      "good": "Good",
      "hazardous": "Hazardous",
      "humidity": "Humidity",
      "humidity_unit": "%",
      "humidity_y_axis": "Humidity %",
      "loading": "Loading",
      "moderate": "Moderate",
      "no2": "NO2",
      "no2_unit": "μg/m³",
      "no2_update": "Last updated: %{time}",
      "no2_y_axis": "NO2 μg/m³",
      "no_data": "No Data",
      "pm10": "PM10",
      "pm10_unit": "μg/m³",
      "pm10_y_axis": "PM10 μg/m³",
      "pm25_unit": "μg/m³",
      "pm25_y_axis": "PM2.5 μg/m³",
      "pressure": "Pressure",
      "pressure_unit": "Pa",
      "pressure_y_axis": "Pressure Pa",
      "temperature": "Temperature",
      "temperature_unit": "°C",
      "temperature_y_axis": "Temp °C",
      "unhealthy": "Unhealthy",
      "unhealthy_sensitive": "Unhealthy for sensitive graoups",
      "very_unhealthy": "Very unhealthy",
      "windspeed": "Windspeed",
      "windspeed_unit": "m/s",
      "windspeed_y_axis": "Windspeed m/s"
    },
    "oaq_graph": "Airspot Graph",
    "range": "Range",
    "time": {
      "all_registers": "All",
      "analytics_lines": "Analytics",
      "auto_update_every_minute": "* Auto update every minute",
      "avg": "Average",
      "change_button": "Change",
      "daily": {
        "every_day": "Filter by Day",
        "to_yesterday": "As of yesterday"
      },
      "energy": "Energy Use",
      "energy_consumption": "Energy Consumption",
      "env_data": "Env. Data",
      "hourly": {
        "every_hour": "Filter by Hour",
        "to_last_hour": "as of last hour"
      },
      "humidity": "Humidity",
      "humidity_axis_title": "%{name} (%)",
      "last_1_hour": "Last 1 Hour",
      "loading": "Loading...",
      "max": "Max",
      "min": "Min",
      "monthly_average": "Monthly Average",
      "monthly_highest": "Monthly Max",
      "monthly_lowest": "Monthly Min",
      "monthly_total": "Monthly Total",
      "target_hit_rate": "Quota Hit Rate",
      "temperature": "Temperature",
      "temperature_axis_title": "%{name} (°C)",
      "thirty_minute": {
        "to_last_half_hour": "as of last half hour"
      },
      "time_position": {
        "1080_hours_ago": "45 Days Ago",
        "168_hours_ago": "7 Days Ago",
        "2160_hours_ago": "3 Months Ago",
        "720_hours_ago": "30 Days Ago",
        "half_year_ago": "Half Year Ago",
        "latest": "Latest",
        "one_month_ago": "1 Month Ago",
        "three_months_ago": "3 Months Ago"
      },
      "total": "Total",
      "usage": "Usage",
      "weather_source": {
        "accuweather": "Temperature and humidity are sourced from <a href=\"https://www.accuweather.com\" target=\"_blank\" rel=\"noopener noreferrer\">AccuWeather</a>",
        "hko": "Temperature and humidity are sourced from <a href=\"http://www.hko.gov.hk/contente.htm\" target=\"_blank\" rel=\"noopener noreferrer\">Hong Kong Observatory</a>"
      },
      "yaxis": "Unit"
    },
    "time_graph": "Energy Graph"
  },
  "green_index": {
    "annual_coming_soon": "年度大回顧即將上線，敬請期待！",
    "annual_report": "下載年度大回顧:",
    "instruction_1": "圖表總結過去一個月或一年實際用量跟綠色指數的比較。<br/>綠色指數由人工智能設置，代表單位合理的用電量。<br/>每次總結單位服務質素，不妨把用電表現加入討論。利用以下口號，方便大家研究。",
    "instruction_2": "用量遊走綠色區，用電達標無難度。<br/>用電高於綠色區，研究用電免超支。",
    "instruction_3": "圖表於每月一號更新，按以下連結可下載過往十二個月份的資料。",
    "monthly_coming_soon": "每月小總結即將上線，敬請期待！",
    "monthly_report": "下載每月小總結:"
  },
  "head_section": {
    "missing_record": {
      "description": "<h3>Attention!</h3>This location or sub-location is under maintenance. Energy data may be deviated. Sorry for any inconveniences caused."
    },
    "shared_ct": {
      "description": "Your current selection usage is shared. Let's work together!"
    }
  },
  "helpers": {
    "actions": "Actions",
    "links": {
      "back": "Back",
      "cancel": "Cancel",
      "confirm": "Are you sure?",
      "destroy": "Delete",
      "edit": "Edit",
      "new": "New"
    },
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    },
    "titles": {
      "delete": "Delete %{model}",
      "edit": "Edit %{model}",
      "new": "New %{model}",
      "save": "Save %{model}"
    }
  },
  "home": {
    "appliance_status": {
      "available": "AVAILABLE NOW",
      "average": "AVERAGE",
      "disconnected": "DISCONNECTED",
      "high": "HIGH",
      "label": "Appliance Status",
      "low": "LOW",
      "no_appliance_status": "Select a Location to Display Appliance Status...",
      "occupied": "OCCUPIED NOW",
      "popup": {
        "average": "Consumption is at normal level (Hourly Appliance Usage compared to past 4 %{weekday})",
        "high": "Consumption is at least 15% above average (Hourly Appliance Usage compared to past 4 %{weekday})",
        "low": "Consumption is at least 15% below average (Hourly Appliance Usage compared to past 4 %{weekday})"
      }
    },
    "current_location_message": "You are currently at %{location}",
    "current_location_message_ust": "Welcome to %{location}",
    "performance": {
      "label": "CURRENT PERFORMANCE",
      "peer_rank": "RANK",
      "self_change": "LAST WEEK"
    },
    "today": {
      "iaq": {
        "disconnected": "Disconnected",
        "fair": "Fair",
        "healthy": "Healthy",
        "label": "IAQ Score",
        "loading": "Loading",
        "moderate": "Moderate",
        "multiple": "Score displayed below is the average of multiple IAQ devices",
        "poor": "Poor",
        "unhealthy": "Unhealthy"
      },
      "left": {
        "good": "Good! Keep it up",
        "high": "Consumption faster than normal! Try to switch off some appliances",
        "label": "Remaining Energy Consumption Quota for Today",
        "label_ust": "Remaining Energy Consumption Budget for Today",
        "ok": "In normal usage"
      },
      "oaq": {
        "disconnected": "Disconnected",
        "good": "Good",
        "hazardous": "Hazardous",
        "label": "Realtime NO2 Reading",
        "last_update": "Last updated: %{time}",
        "loading": "Loading",
        "moderate": "Moderate",
        "unhealthy": "Unhealthy",
        "unhealthy_sensitive": "Unhealthy for sensitive graoups",
        "very_unhealthy": "Very unhealthy"
      },
      "oaq_chart": {
        "2_days_ago": "2 Days Ago",
        "label": "Past 2 Days' NO2 Readings",
        "last_24_hours": "Last 24 Hours",
        "last_update": "Last updated: %{time}",
        "loading": "Loading",
        "unit": "μg/m3"
      },
      "used": {
        "daily_target_description": "%{percentage} of today's quota",
        "daily_target_description_ust": "%{percentage} of today's budget",
        "description": {
          "cost": "electricity fees",
          "iphone": "iPhone charging",
          "telsa": "electric vehicle travel distance"
        },
        "label": "Today's Energy Consumption",
        "times": "times"
      }
    },
    "ust_budget": {
      "budget": "budget",
      "energy_budget": "Energy Budget",
      "energy_used": "Energy Used",
      "kwh_to_points": "1 kWh = 10 points",
      "label": "Energy Budget",
      "overall_points_left": "Overall points left:",
      "overuse": "You've exceeded your total energy budget thus far.",
      "points": "points",
      "to": "to",
      "today": "Today",
      "underuse": "You're within your total energy budget thus far."
    },
    "ust_rolling_chart": {
      "2_days_ago": "2 Days Ago",
      "3_days_ago": "3 Days Ago",
      "label": "Past 3 days Consumption",
      "last_24_hours": "Last 24 Hours",
      "points": "points"
    }
  },
  "how_to": {
    "comparison": {
      "description": "Get involved! Compete with your friends and family to see who can save the extra dollar.",
      "description_ust": "Review your past 4 week’s energy summary"
    },
    "daily_quota": {
      "description": "Experience the power of A.I. in energy saving - see how our machine sets a daily energy quota for you."
    },
    "graph": {
      "description": "Get to the bottom of your energy use here. Embark on your journey to read and compare highs and lows of usage. Discover saving opportunities in different seasons."
    },
    "home": {
      "description": "You think energy data is hard to read? Here, we show you how to get an overview of energy data in a few seconds."
    },
    "notification": {
      "description": "Too busy to visit dashboard often? Our notification gets you on top of every energy trends that should not be missed."
    },
    "page_head": "How-to Guide",
    "profile": {
      "description": "Always update your personal info here to stay connected."
    },
    "report": {
      "description": "Get your raw energy data here of the last 90 days. Give us the dates, interval and location. Your report will be ready shortly."
    }
  },
  "iaq_matrix": {
    "co2": "CO2",
    "co2_unit": "ppm",
    "humidity": "Humidity",
    "humidity_unit": "%",
    "pm25": "PM2.5",
    "pm25_unit": "μg/m³",
    "score": "IAQ Score",
    "score_unit": "score",
    "temperature": "Temperature",
    "temperature_unit": "°C",
    "voc": "VOC",
    "voc_unit": "ppb"
  },
  "location": {
    "close": "Close",
    "tab": "Location",
    "zoningplan": "Zoning Plan"
  },
  "login": {
    "disclaimer": "For PICS and Privacy Policy Statement, please visit <a href=\"http://www.hibluesky.co/terms\" target=\"_blank\" rel=\"noopener noreferrer\">here</a>.",
    "email": "Email (For first time users: First-time login username)",
    "first_name": "First Name",
    "forget_password": "Forgot your password?",
    "forget_password_send_instruction": "Reset Password",
    "inactive_login": "This account has been deactivated",
    "last_name": "Last Name",
    "log_in": "Log in",
    "password": "Password (For first time users: Activation code)",
    "password_confirmation": "Confirm Password",
    "registration_unauthorized_access": "Registration page cannot be accessed",
    "remember_me": "Remember me",
    "set_password": "Password (%{length} characters minimum)",
    "sign_in_header": "Sign in to empower my energy",
    "sign_up": "Sign Up",
    "sign_up_header": "Sign up to empower my energy"
  },
  "menu": {
    "admin": "ADMIN",
    "comparison": "COMPARISON",
    "daily_target": "DAILY QUOTA",
    "faq": "FAQ",
    "graph": "GRAPH",
    "green_index": "GREEN INDEX",
    "home": "HOME",
    "how_to": "HOW-TO GUIDE",
    "language": "語言 LANG.",
    "lock": "LOCK SCREEN",
    "log_out": "LOGOUT",
    "multimedia": "MULTIMEDIA",
    "notification": "NOTIFICATION",
    "notifications": "NOTIFICATIONS",
    "payment": "PAYMENT",
    "profile": "PROFILE",
    "report": "REPORT",
    "social": "COMMUNITY",
    "status": "STATUS",
    "unlock": "UNLOCK SCREEN",
    "ust_info_page": "INFO PAGE",
    "video": "VIDEO"
  },
  "notification": {
    "create_user": {
      "body": "Let's check out our features.",
      "title": "Welcome to Blue Sky Dashboard!"
    },
    "user_event": {
      "abnormality": {
        "faq_link": "https://hibluesky.helpsite.com",
        "graph": {
          "greenindex": "Green Index",
          "high": "High",
          "low": "Low",
          "normal": "Normal"
        },
        "overuse": {
          "body_html": "%{location}'s energy usage yesterday was %{value}kWh, %{percent}% above the Green Index's upper bound! Let's investigate why.",
          "most_consuming": "The most energy consuming appliance: %{appliance}",
          "title": "Oops! Yesterday your energy usage was unusually high!"
        },
        "underuse": {
          "body_html": "%{location}'s energy usage yesterday was %{value}kWh, %{percent}% below the Green Index's lower bound!",
          "title": "Good Job! Your energy usage yesterday was lower than normal !"
        }
      },
      "about_target_alert": {
        "body_html": "Your location energy only have less than 20% versus today's quota. Let's find out below location if it is normal. <div>%{locations}</div>",
        "title": "Ouch! Looks like you are spending energy more than usual."
      },
      "aqhi_alert": {
        "body_html": "Outdoor air quality health risk at %{location_name} is forecasted to reach %{level} today. %{oaq_advice} Air pollutants from outdoor may enter indoor through opened doors and windows. When air pollution gets worse, you are advised to close the windows, and turn on the air purifiers and ventilation systems to minimize the impact on your health.<br/><br/>Outdoor air quality alert powered by Blue Sky and PRAISE-HK",
        "high": "high",
        "serious": "serious",
        "title": "Outdoor air quality health risk at %{location_name} may reach %{level} today",
        "very_high": "very high"
      },
      "baseload_comparison_alert": {
        "body_html": "Some appliances were secretly spending %{percent}% higher than past 30 day's average at %{hours}. Let's investigate why they work so hard overnight!",
        "title": "Is it possible that one or more appliance(s) were left on overnight?"
      },
      "budget_success_rate": {
        "body_html": "In the past month, you have met your budget %{success_days} out of %{total_days} days (%{rate}%). Find out more ways to save energy <a href=\"http://green.ust.hk/en-us/energyquotaexp/\">here</a>.",
        "title": "Energy budget performance: %{rate}% success rate"
      },
      "can": {
        "blackspot": {
          "body_html": "Your place has an average %{percent}% of NO2 higher than the city average! Please contact the nearby district councillor to reflect the pollution issue.",
          "title": "You are at a pollution blackspot!!"
        },
        "clean_air_spot": {
          "body_html": "Your place was a clean air spot and was %{percent}% lower than the WHO standard.",
          "title": "This was the Clean Air Spot of the last month"
        },
        "health_hazard": {
          "body_html": "The current NO2 concentration at %{time} is much higher than the safety level. Is it because of the nearby heavy traffic? Let's make sure you are not exposed in the area for a long period of time.",
          "title": "Health hazard warning"
        }
      },
      "daily_socket_comparison_alert": {
        "body_html": "At 3:00am last night, sockets were using %{percent}% more energy than 11:00am yesterday. Was there any appliance left on overnight unattended? Hope you were not working overtime.<br/> Let's find out more in the graph section of dashboard.",
        "title": "Socket during midnight uses %{percent}% more energy than that in the morning!"
      },
      "daily_usage_comparison_alert": {
        "body_html": "Your total power comsumption yesterday was %{percent}% more than the past 30-day average. The highest rise appliance is %{register}. Visit the graph and let's find out why.",
        "title": "Hmm, your power use goes up %{percent}% yesterday."
      },
      "daily_usage_comparison_celebration": {
        "body_html": "Your area had utilized %{percent}% less than the past 30-day average. The highest reduction appliance is %{register}. Share with others for your tips and work!",
        "title": "Great Job, you used %{percent}% less energy yesterday."
      },
      "exceed_x_percent": {
        "body_html": "You've used %{percent}% of your budget. If you continue your current rate of energy consumption, you're expected to exceed your budget on %{date}. The scheme will end on 11/30, so budget wisely to earn rewards！",
        "title": "You have used %{percent}% of your total energy budget"
      },
      "excess_co2_alert": {
        "action_tip": "<br/><br/>Tip: %{action_tip}",
        "body_html": "Carbon dioxide levels at %{location_name} were high for the past hour. High carbon dioxide levels can make you sleepy, less productive and decrease cognitive scores by 50% according to research studies.",
        "title": "High CO2 levels at %{location_name}"
      },
      "excess_pm25_alert": {
        "action_tip": "<br/><br/>Tip: %{action_tip}",
        "body_html": "PM2.5 levels at %{location_name} were abnormally high for the past hour. Short-term exposure causes coughing and breathing difficulties. Long term exposure to PM2.5 can increase risk of heart and lung cancer mortality.",
        "title": "High PM2.5 levels at %{location_name}"
      },
      "excess_voc_alert": {
        "action_tip": "<br/><br/>Tip: %{action_tip}",
        "body_html": "VOC levels at %{location_name} were abnormally high for the past hour. Excess VOCs can affect cognitive functions, such as problem-solving and decision making.",
        "title": "High VOCs levels at %{location_name}"
      },
      "humidity_alert": {
        "high": {
          "action_tip": "<br/><br/>Tip: %{action_tip}",
          "body_html": "Humidity at %{location_name} was above 70% for the past hour. Excessively humid air increases mold growth, and causes dust mites, bacteria, fungi and influenza viruses to live longer.",
          "title": "High humidity levels at %{location_name}"
        },
        "low": {
          "action_tip": "<br/><br/>Tip: %{action_tip}",
          "body_html": "Humidity at %{location_name} was below 40% for the past hour. Excessively dry air can irritate eyes, cause a stuffy nose, aggravate allergies and even cause certain viruses to live longer. Turn on your humidifier to increase the humidity.",
          "title": "Low humidity levels at %{location_name}"
        }
      },
      "iaq_score_alert": {
        "body_html": "Your IAQ score today is %{score} (%{level}). Enhance IAQ by improving ventilation.",
        "title": "%{level} indoor air quality at %{location_name}"
      },
      "meter_offline": {
        "location_missed": "%{location} (%{missing_days} days missed)",
        "note": "We detected that smart meters were disconnected in the above location(s). There may need a fix to get those smart meter(s) back online. Once this is done, the system will automatically recover the energy monitoring within 3 hours. If you need further assistance in resolving this, feel free to contact us through info@hibluesky.co.",
        "title": "Smart Meter(s) Went Offline"
      },
      "monthly_budget": {
        "exceed": {
          "body_html": "Yikes! You exceeded your %{month} energy budget. From now on, stay well under your budget to earn rewards and avoid paying for AC.",
          "title": "You exceeded your %{month} energy budget"
        },
        "within": {
          "body_html": "You were within your budget last month. Keep it up to earn rewards: solar-powered energy gadgets, fans, and other goodies!",
          "title": "You're on track for your %{month} energy budget"
        }
      },
      "office_hour_comparison_alert": {
        "body_html": "We noticed your energy usage at %{hour} was %{percent}% over last 30 days average use. The highest rise appliance is %{register}. <br/> Was there a specific event? Let's take a look what's happened.",
        "title": "Oh, you used %{percent}% more energy in the last hour."
      },
      "office_hour_comparison_celebration": {
        "body_html": "You must had done something great to help saving energy because your energy usage at %{hour} was %{percent}% less than past 30 days average. <br/> The highest reduction appliance is %{register}. Share what you had done to others and keep it up!",
        "title": "Well done! You used %{percent}% less energy than before!"
      },
      "overall_exceed_budget": {
        "body_html": "You've exceeded your semester budget. You'll now have to pay back the total cost of your AC for the semester, so use less to avoid paying more.",
        "title": "Semester budget exceeded - charges applied from now on"
      },
      "overuse_appliance": {
        "action_tip": "<br/><br/>Tip: %{action_tip}",
        "body_html": "<b>Your %{register} use at %{location} was abnormally high from %{start_hour} to %{end_hour} today.</b>",
        "title": "High %{register} use at %{location}"
      },
      "peer_comparison": {
        "action_tip": "<br/><br/>Tip: %{action_tip}",
        "low": {
          "body_html": "<b>Your energy use was higher than 80% of users at %{parent_location_name} last month</b><br/><br/>We all want clean air - electricity generation contributes to 67% of carbon emissions in Hong Kong. Use less energy to reduce air pollution!",
          "title": "%{location_name} usage was higher than the majority of locations at %{parent_location_name}."
        },
        "middle": {
          "body_html": "<b>Your energy use is ranked %{rank} out of %{peer_count} last month.</b><br/><br/>We all want clean air - electricity generation contributes to 67% of carbon emissions in Hong Kong. Use less energy to reduce air pollution!",
          "title": "Energy Ranking - %{rank} out of %{peer_count}."
        },
        "top": {
          "body_html_1st": "<b>Congratulations on being 1st!</b><br/><br/>You're ranked %{rank} out of %{peer_count} last month. You’ve avoided emitting %{kg_of_co2} kg of carbon dioxide.",
          "body_html_2nd": "<b>Congratulations on being 2nd!</b><br/><br/>You're ranked %{rank} out of %{peer_count} last month. You’ve avoided emitting %{kg_of_co2} kg of carbon dioxide.",
          "body_html_3rd": "<b>Congratulations on being 3rd!</b><br/><br/>You're ranked %{rank} out of %{peer_count} last month. You’ve avoided emitting %{kg_of_co2} kg of carbon dioxide.",
          "title": "Energy Ranking - %{rank} out of %{peer_count}."
        }
      },
      "temperature_alert": {
        "high": {
          "action_tip": "<br/><br/>Tip: %{action_tip}",
          "body_html": "Temperature at %{location_name} was above 30°C for the past hour which may cause dizziness or headache. ",
          "title": "High temperature at %{location_name}"
        },
        "low": {
          "action_tip": "<br/><br/>Tip: %{action_tip}",
          "body_html": "Temperature at %{location_name} was under 12°C for the past hour which may cause thermal discomfort.",
          "title": "Low temperature at %{location_name}"
        }
      },
      "update_target_reminder": {
        "body": "Looks like you haven't prepared quota next month. It is time. Click here to go to set your target!",
        "title": "Quota for next month. Let's take action before it is too late."
      },
      "weather_notification": {
        "title": "According to weather tomorrow, here's action tip(s)"
      },
      "weekly_budget": {
        "exceed": {
          "action_tips": "<br/><br/>Tips:<br/>1. %{action_tip_1}<br/>2. %{action_tip_2}",
          "body_html": "Yikes! You exceeded your weekly budget because of high %{appliance} use. Use less from now on to earn rewards and avoid paying a penalty.",
          "title": "You exceeded your weekly energy budget"
        },
        "within": {
          "body_html": "You've used less energy than last week's budget. Keep up the good work to earn rewards at the end of the semester, including solar-powered energy gadgets, fans and other goodies!",
          "title": "You're on track for your weekly energy budget"
        }
      }
    }
  },
  "notification_group": {
    "abnormality": {
      "description": "Alerts for irregularities in energy use",
      "name": "Green Index Alert"
    },
    "bluesky": {
      "description": "Announcements from Blue Sky, sent occasionally",
      "name": "Blue Sky's Message"
    },
    "calendar": {
      "description": "Messages for special events, e.g. Earth Hour and holidays",
      "name": "Special Events"
    },
    "can": {
      "description": "Outdoor air quality updates",
      "name": "Clean Air Network Related"
    },
    "energy_use_status": {
      "description": "Daily energy summary",
      "name": "Energy Use Status"
    },
    "fun_energy_fact": {
      "description": "Trivia related to energy, sent occasionally",
      "name": "Fun Energy Facts"
    },
    "hku": {
      "description": "Notifications specific to HKU",
      "name": "HKU-related Notifications"
    },
    "iaq": {
      "description": "Alerts for unhealthy indoor air quality",
      "name": "IAQ Alert"
    },
    "meter_offline": {
      "description": "Alerts for when smart meters disconnect",
      "name": "Meter Maintenance"
    },
    "oaq": {
      "description": "Alerts for unhealthy outdoor air quality",
      "name": "OAQ Alert"
    },
    "overuse_appliance": {
      "description": "Alerts for when appliances use too much energy",
      "name": "High Consumption Alert"
    },
    "peer_comparison": {
      "description": "Monthly ranking against other users at your location",
      "name": "Energy Ranking"
    },
    "ust_budget": {
      "description": "Energy budget scheme updates",
      "name": "HKUST Budget Scheme"
    },
    "weekly_summary": {
      "description": "Weekly analytics summary",
      "name": "Weekly Summary"
    }
  },
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "oaq_matrix": {
    "co": "CO",
    "co_unit": "ppm",
    "humidity": "Humidity",
    "humidity_unit": "%",
    "latitude": "Latitude",
    "latitude_unit": "°",
    "logitude": "Longitude",
    "logitude_unit": "°",
    "no2": "NO2",
    "no2_unit": "μg/m³",
    "pm10": "PM10",
    "pm10_unit": "μg/m³",
    "pm25": "PM2.5",
    "pressure": "Pressure",
    "pressure_unit": "Pa",
    "temperature": "Temperature",
    "temperature_unit": "°C",
    "windspeed": "Windspeed",
    "windspeed_unit": "m/s"
  },
  "payment": {
    "content": "Pay amount of AC = $ %{fee}",
    "pay_now": "Pay Now",
    "title": "Payment for %{location}"
  },
  "profile": {
    "current_password_hint": "required for the update",
    "login_cannot_change": "<i class=\"fa fa-lock\"></i> cannot change",
    "password_again": "password again",
    "password_leave_blank": "leave blank if you don't want to change it",
    "section": {
      "basic_information": "Basic Information",
      "change_password": "Change Password",
      "data_driven_alert": "Data-Driven Alert",
      "latest_news": "Latest News",
      "notification": "Notification",
      "save_changes": "Save Changes"
    },
    "update_button": "Update"
  },
  "report": {
    "all_locations": "All Locations",
    "appliance_category": "Appliance Category",
    "at": "Time (date)",
    "at_dow": "Time (day of week)",
    "at_hour": "Time (hour)",
    "daily": "Daily",
    "daily_quota": "Daily Quota",
    "date_range": "Date Range",
    "device_name": "Device Name",
    "energy_data": "Energy Data",
    "export": "Export",
    "export_format": "Export File Format",
    "fifteen_minute": "Every fifteen minutes",
    "group_by_appliance": "Group by Appliance",
    "hourly": "Hourly",
    "humidity": "Humidity",
    "iaq_data": "IAQ Data",
    "in_on_peak": "Yes",
    "include_children?": "Include sub-locations?",
    "interval": "Interval",
    "location": "Location",
    "location_description": "Location Description",
    "meet_daily_quota": "Meet Daily Quota?",
    "meet_quota": "Yes",
    "miss_quota": "No",
    "not_in_on_peak": "No",
    "oaq_data": "OAQ Data",
    "on_peak": "On Peak?",
    "ouput_format": "Output Format",
    "path": "Location Path",
    "range-note-daily": "90 days of records are available for download",
    "range-note-fifteen_minute": "60 days of records are available for download",
    "range-note-hourly": "45 days of records are available for download",
    "range-note-thirty_minute": "45 days of records are available for download",
    "raw": "Raw Data",
    "register_name": "Name",
    "register_value": "Value",
    "temperature": "Temperature",
    "ten_minute": "Every ten minutes",
    "thirty_minute": "Half Hourly",
    "time_range": "Time Range",
    "total": "Total",
    "unit": "Unit",
    "week": "Weekly"
  },
  "select_location": {
    "all": "All location",
    "label": "SELECT LOCATION",
    "search": "Search"
  },
  "simple_form": {
    "error_notification": {
      "default_message": "Please review the problems below:"
    },
    "no": "No",
    "required": {
      "mark": "*",
      "text": "required"
    },
    "yes": "Yes"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "AM",
    "formats": {
      "daily_report": "%Y-%m-%d",
      "default": "%a, %d %b %Y %H:%M:%S %z",
      "hourly_graph": "%H:%M %b %-d",
      "hourly_report": "%Y-%m-%d %H:%S",
      "long": "%B %d, %Y %H:%M",
      "self_comparison": "%d %b",
      "short": "%d %b %H:%M",
      "today_daily_use": "%a",
      "us": "%m/%d/%Y %I:%M %p"
    },
    "pm": "PM"
  },
  "user_notification_preference": {
    "confirm_reset": "Confirm reset?",
    "email": "Email",
    "enable": "Enable",
    "push": "Mobile Notifications",
    "reset_original": "Reset to default"
  },
  "user_target": {
    "choose_a_location": "Please choose a location",
    "create_button": "Create",
    "create_succeed": "New quota is set.",
    "current_target": "Current Quota",
    "desktop_for_configuration": "Please switch to desktop version for configuration details",
    "destroy_succeed": "Quota is removed.",
    "energy_use_kVA": "Unit (kVA)",
    "energy_use_kVAh": "Unit (kVAh)",
    "energy_use_kW": "Unit (kW)",
    "energy_use_kWh": "Unit (kWh)",
    "more_histroy": "More history",
    "new_user_target": "New Quota",
    "no_target": "No quota has been set",
    "past_average_1month": "Past Avg. <br/>(1 month)",
    "past_average_3month": "Past Avg. <br/>(3 month)",
    "reduction": "Compare to past %{month} month, I want to save...",
    "report": {
      "effective?": "Effective?",
      "effective_on": "Effective Date",
      "include_history": "Include history?",
      "location": "Location",
      "location_description": "Location Description",
      "n": "No",
      "set?": "Set?",
      "y": "Yes"
    },
    "set_new_target": "Set a new quota?",
    "targets": "History Quotas",
    "title": "User Assigned Quota",
    "vs": "New Target VS past %{month} month"
  },
  "video": {
    "audio": "Interested in listening the song? Click the button next! ",
    "countdown": "Video will be played in ",
    "finish": "Thanks for watching!",
    "seconds": " seconds"
  },
  "will_paginate": {
    "next_label": "Next &#8594;",
    "page_entries_info": {
      "multi_page": "Displaying records %{from} - %{to} of %{count} in total",
      "multi_page_html": "Displaying records <b>%{from}&nbsp;-&nbsp;%{to}</b> of <b>%{count}</b> in total",
      "single_page": {
        "one": "Displaying 1 record",
        "other": "Displaying all %{count} records",
        "zero": "No record found"
      },
      "single_page_html": {
        "one": "Displaying <b>1</b> record",
        "other": "Displaying <b>all&nbsp;%{count}</b> records",
        "zero": "No record found"
      }
    },
    "page_gap": "&hellip;",
    "previous_label": "&#8592; Previous"
  }
});
I18n.translations["zh-TW"] = I18n.extend((I18n.translations["zh-TW"] || {}), {
  "activerecord": {
    "attributes": {
      "user": {
        "activation": "初次登入帳號",
        "client": "客戶",
        "current_password": "現在密碼",
        "email": "登入電郵",
        "first_name": "名字",
        "gender": "姓別",
        "last_name": "姓氏",
        "location": "位置",
        "name": "名字",
        "notification_email": "通知電郵",
        "password": "登入密碼",
        "password_confirmation": "重覆登入密碼",
        "user_type": "類型"
      },
      "user_preference": {
        "language": "語言",
        "send_abnormality_notification": "接收綠色指數警示",
        "send_energy_use_status_email": "接收用電量速報",
        "send_notification_reminder_email": "接收通知提醒電郵",
        "send_office_hour_comparison_alert": "接收辦公時間用電量警示",
        "send_overuse_appliance_notification": "接收電器用電量過高警示",
        "send_weekly_summary_email": "接收每週總結"
      },
      "user_target_template": {
        "effective_on": "生效日"
      }
    },
    "errors": {
      "models": {
        "user": {
          "attributes": {
            "current_password": {
              "blank": "不能空白"
            },
            "email": {
              "blank": "請輸入電郵"
            },
            "notification_email": {
              "invalid": "請輸入正確的電郵格式"
            },
            "password": {
              "blank": "不能空白"
            },
            "password_confirmation": {
              "confirmation": "密碼不匹配"
            }
          }
        },
        "user_target_template": {
          "attributes": {
            "effective_on": {
              "blank": "不能空白",
              "not_a_date": "不是日期"
            },
            "friday": {
              "blank": "不能空白",
              "greater_than_or_equal_to": "不是正整數",
              "not_a_number": "不是數字",
              "not_an_integer": "不是整數"
            },
            "monday": {
              "blank": "不能空白",
              "greater_than_or_equal_to": "不是正整數",
              "not_a_number": "不是數字",
              "not_an_integer": "不是整數"
            },
            "saturday": {
              "blank": "不能空白",
              "greater_than_or_equal_to": "不是正整數",
              "not_a_number": "不是數字",
              "not_an_integer": "不是整數"
            },
            "sunday": {
              "blank": "不能空白",
              "greater_than_or_equal_to": "不是正整數",
              "not_a_number": "不是數字",
              "not_an_integer": "不是整數"
            },
            "thursday": {
              "blank": "不能空白",
              "greater_than_or_equal_to": "不是正整數",
              "not_a_number": "不是數字",
              "not_an_integer": "不是整數"
            },
            "tuesday": {
              "blank": "不能空白",
              "greater_than_or_equal_to": "不是正整數",
              "not_a_number": "不是數字",
              "not_an_integer": "不是整數"
            },
            "wednesday": {
              "blank": "不能空白",
              "greater_than_or_equal_to": "不是正整數",
              "not_a_number": "不是數字",
              "not_an_integer": "不是整數"
            }
          }
        }
      }
    },
    "models": {
      "user": "用戶"
    }
  },
  "admin": {
    "user": {
      "action_succeed": "%{action} user was successful"
    }
  },
  "appliance_categories": {
    "ac": "空調",
    "all": "所有電器",
    "cooking_appliance": "廚具",
    "fridge": "雪櫃",
    "light": "電燈",
    "mixed_usage": "未分類",
    "other": "其他",
    "socket": "插座",
    "water_heater": "熱水器"
  },
  "comparison": {
    "energy_tab": "能源",
    "iaq_tab": "室內空氣質素",
    "label": "比較",
    "oaq_tab": "室外空氣質素",
    "peer": {
      "all_appliances": "全部電器",
      "customized_from_to": "正在觀看自訂比較日期，顯示由 %{start_time} 至 %{end_time} 的用量比較",
      "customized_from_to_iaq": "正在觀看自訂比較日期，顯示由 %{start_time} 至 %{end_time} 的室內空氣質素比較",
      "customized_from_to_oaq": "正在觀看自訂比較日期，顯示由 %{start_time} 至 %{end_time} 的室外空氣質素比較",
      "energy_today": "今天由%{start_time}至%{end_time}的用電量總和，每小時更新。",
      "iaq_today": "今天由%{start_time}至%{end_time}的室內空氣質素表現，每十五分鐘更新。",
      "loading": "載入中...",
      "me": "我",
      "not_available": "不適用",
      "oaq_today": "今天由%{start_time}至%{end_time}的室外空氣質素表現。",
      "title": "同組比較",
      "toggle": "轉換"
    },
    "self": {
      "current_week_skip_reason": "因本週尚未完結，以上比較並未包括本週",
      "end_date": "至",
      "percentage_change": "變化",
      "start_date": "由",
      "title": "每週比較",
      "week": "星期"
    },
    "ust_public": {
      "range_from_to": "累計用電量由 %{start_time} 截至 %{end_time}",
      "title": "UG Hall 6 用電量比較"
    }
  },
  "daily_target": {
    "change_fail": "錯誤！選項未能更改",
    "change_succeed": "選項已經更改",
    "choose": "選擇",
    "chosen": "已選",
    "not_support": "對不起，這地點不支援設定每日目標",
    "review": "修改",
    "system_assigned": "系統決定",
    "system_description": "我們知道設置每日用電目標充滿挑戰，所以藍天計劃利用人工智能 (A.I.) 系統為你效勞。馬上使用系統自設目標，自動為你訂立節能大計。你可以隨時從圖表頁看看系統設定的數字。<br/><br/>初次使用？建議選擇 \"預設節能策略\"來感受系統的預測能力。<br/><br/>想接受節能挑戰？選擇減3%， 5% 或 8% ，把系統自設目標再減低。",
    "title": "每日目標",
    "user_assigned": "用戶決定",
    "user_description": "下定決心為自己設定目標？在這裡點擊『選擇』，以舊有模式手動設定用電量，你可以按當日活動設定最合適目標。"
  },
  "date": {
    "abbr_day_names": [
      "週日",
      "週一",
      "週二",
      "週三",
      "週四",
      "週五",
      "週六"
    ],
    "abbr_month_names": [
      null,
      "1月",
      "2月",
      "3月",
      "4月",
      "5月",
      "6月",
      "7月",
      "8月",
      "9月",
      "10月",
      "11月",
      "12月"
    ],
    "date_names": [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六"
    ],
    "day_names": [
      "星期日",
      "星期一",
      "星期二",
      "星期三",
      "星期四",
      "星期五",
      "星期六"
    ],
    "formats": {
      "daily_graph": "%b%-d日",
      "iaq_weekly_summary_v1_month": "%b",
      "weekly_summary_v2_month": "%b"
    }
  },
  "daterangepicker": {
    "apply_label": "應用",
    "cancel_label": "取消",
    "custom_range_label": "自選",
    "days_of_week": [
      "日",
      "一",
      "二",
      "三",
      "四",
      "五",
      "六"
    ],
    "from_label": "由",
    "last_24_hours": "過去24小時",
    "last_30_days": "過去30天",
    "last_7_days": "過去7天",
    "last_month": "上月",
    "month_names": [
      "一月",
      "二月",
      "三月",
      "四月",
      "五月",
      "六月",
      "七月",
      "八月",
      "九月",
      "十月",
      "十一月",
      "十二月"
    ],
    "this_month": "本月",
    "to_label": "至",
    "today": "今天"
  },
  "device": {
    "capability": {
      "incapable": "此裝置並不支援自動播放功能，如需支援請更新系統至最新版本。"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "您的帳號已通過驗證，現在您已成功登入。",
      "send_instructions": "您將在幾分鐘後收到一封電子郵件，內有驗證帳號的步驟說明。",
      "send_paranoid_instructions": "如果我們有您的信箱，您將會收到一封驗證您的帳號的電子郵件。"
    },
    "failure": {
      "already_authenticated": "您已經登入。",
      "inactive": "您的帳號尚未被啟用。",
      "invalid": "信箱或密碼是無效的。",
      "last_attempt": "您還有最後一次嘗試機會，再次失敗您的帳號將會被鎖定。",
      "locked": "您的帳號已被鎖定。",
      "not_found_in_database": "信箱或密碼是無效的。",
      "timeout": "您的登入時效過期，請重新登入。",
      "unauthenticated": "您需要先登入或註冊後才能繼續。",
      "unconfirmed": "您的帳號需需要經過驗證後，才能繼續。"
    },
    "mailer": {
      "confirmation_instructions": {
        "subject": "帳號驗證步驟"
      },
      "password_change": {
        "subject": "密碼修改步驟"
      },
      "reset_password_instructions": {
        "subject": "密碼重設步驟"
      },
      "unlock_instructions": {
        "subject": "帳號解鎖步驟"
      }
    },
    "omniauth_callbacks": {
      "failure": "無法從 %{kind} 驗證，因為 \"%{reason}\"。",
      "success": "成功從 %{kind} 驗證。"
    },
    "passwords": {
      "no_token": "這是密碼重設頁面，僅能透過密碼重設信件進入。如果您是透過重設信件進入的，請確認您的網址是完整的。",
      "send_instructions": "您將在幾分鐘後收到一封電子郵件，內有重新設定密碼的步驟說明。",
      "send_paranoid_instructions": "如果我們有您的信箱，您將會收到一封內含可重新設定密碼連結的電子郵件。",
      "updated": "您的密碼已被修改，您現在已經登入。",
      "updated_not_active": "您的密碼已被修改。"
    },
    "registrations": {
      "destroyed": "再會！您的帳號已被取消。有緣再會。",
      "signed_up": "註冊成功，歡迎！",
      "signed_up_but_inactive": "您已註冊成功。然而因為您的帳號尚未啓動，暫時無法登入，抱歉！",
      "signed_up_but_locked": "您已註冊成功。 然而因為您的帳號已被鎖定，暫時無法登入，抱歉！",
      "signed_up_but_unconfirmed": "確認信件已送至您的 Email 信箱，請點擊信件內連結以啓動您的帳號。",
      "update_needs_confirmation": "您已經成功的更新帳號資訊，但我們仍需確認您的電子信箱，請至新信箱收信並點擊連結以確認您的新電子郵件帳號。",
      "updated": "您已經成功的更新帳號資訊。"
    },
    "sessions": {
      "already_signed_out": "成功登出了。",
      "signed_in": "成功登入了。",
      "signed_out": "成功登出了。"
    },
    "unlocks": {
      "send_instructions": "您將在幾分鐘後收到一封電子郵件，內有將帳號解除鎖定的步驟說明。",
      "send_paranoid_instructions": "如果您的帳號已存在，您的電子信箱將會收到如何解鎖帳號的指示。",
      "unlocked": "您的帳號已被解鎖，現在已經登入。"
    }
  },
  "email": {
    "change_how_you_receive": "想變更你的電郵送出選項？請到儀錶板的個人資料頁更改。",
    "download_mobile_apps": "下載 Blue Sky 應用程式",
    "energy_use_status": {
      "at": "截至",
      "click_below": "按以下按鈕！",
      "go_to_dashboard": "進入儀錶板",
      "know_more": "想知更多？",
      "of_daily_target": "每日目標",
      "subject": "Blue Sky - 用電量速報"
    },
    "iaq_weekly_summary": {
      "v1": {
        "24_hours": "24小時",
        "average_level": "平均等級",
        "data_incomplete": {
          "html": "由於網絡原因，我們未能連接 <strong>%{location_name}</strong> 的室內空氣質素監測儀。<br/>如需要協助，請聯絡你的管理人員或發送電郵至 <a href='mailto:connect@hibluesky.co'>connect@hibluesky.co</a>。",
          "title": "注意：數據不完整"
        },
        "distribution_graph_title": "室內空氣質量指數等級分佈",
        "faq": {
          "html": "<a href='https://ahblue.helpsite.com/categories/15817-室内空氣質素小貼士'>按此了解常見問題</a>"
        },
        "footer": {
          "question_comment": "有疑問或意見嗎？ 請發送電子郵件至",
          "unsubscribe": "如不欲收到電子通訊，請更改推送設定。"
        },
        "heat_map_title": "室內空氣質量指數熱圖",
        "launch": {
          "html": "這是每週室內空氣質素總結。總結中包括了%{location}全部室內空氣監測儀的平均分數及5種室內空氣質素參數的平均水平。配合室內空氣質素熱圖，希望能提升閣下的數據視覺化體驗。歡迎電郵至<a href='mailto:connection@hibluesky.co'>connect@hibluesky.co</a>告訴我們您的想法。希望您喜歡！",
          "title": "室內空氣質素每週總結"
        },
        "missing_remark": "(空白格表示該時段的數據不完整)",
        "operating_hours": "營運時間",
        "overall": "整體",
        "see_iaq_trends": "查看室內空氣質素趨勢",
        "subject": "Blue Sky - 室內空氣質素每週總結",
        "title": "室內空氣質素每週總結",
        "whole_day": "整天"
      }
    },
    "notification_reminder": {
      "and_more": "更多...",
      "feedback_message": "若你有任何意見或問題，可發送電郵至",
      "response_message": "我們將盡快與你聯絡。",
      "see_trends": "查看趨勢",
      "subject": "Blue Sky - 你有幾個節能提示",
      "unsubscribe_click_here": "按此",
      "unsubscribe_message": "如不欲收到電子通訊，請"
    },
    "sent_from": "此電郵發出自",
    "weekly_summary": {
      "v1": {
        "action": {
          "rationale_with_percentage": "你的 %{appliance} 上升了%{percentage}%。這樣做或許有幫助！",
          "rationale_without_percentage": "你的 %{appliance} 用電量有點高。這樣做或許有幫助！",
          "tips": "長假期前記得熄冷氣！",
          "title": "行動貼士"
        },
        "graph": {
          "decrease": "節省",
          "increase": "多用",
          "last_week": "上週",
          "title": "每日用電量",
          "two_weeks_ago": "兩週前",
          "xaxis": [
            "一",
            "二",
            "三",
            "四",
            "五",
            "六",
            "日"
          ]
        },
        "greeting": {
          "checkout": "看看上週用電量跟兩週前的比較",
          "hi": "你好 %{name}！"
        },
        "metric": {
          "fun_fact": "趣味資料",
          "fun_fact_description": "駕駛電動車由尖沙嘴到機場的次數",
          "fun_fact_unit": "次",
          "total_energy": "用電量",
          "total_energy_description": "你的地點用電量總和"
        },
        "remark": {
          "warning": "因技術問題，以上顯示的資料可能不完整"
        },
        "subject": "Blue Sky - 每週總結"
      },
      "v2": {
        "data_incomplete": {
          "html": "由於 <strong>%{location_name}</strong> 的智能電錶網絡或能源預算不穩定。<br/>以下數據可能不是最準確的。<br/>如需要協助，請聯絡你的管理人員或發送電郵至 <a href='mailto:connect@hibluesky.co'>connect@hibluesky.co</a>。",
          "title": "注意：數據不完整"
        },
        "energy_consumption": {
          "iphone": "充滿iPhone的次數",
          "iphone_unit": "次",
          "tesla": "電動車行駛距離",
          "title": "上週的用電數據"
        },
        "energy_distribution": {
          "title": "用電量分佈"
        },
        "footer": {
          "question_comment": "有疑問或意見嗎？ 請發送電子郵件至",
          "unsubscribe": "如不欲收到電子通訊，請更改推送設定。"
        },
        "graph": {
          "distribution_graph": {
            "x_label": [
              "兩週前",
              "上週"
            ]
          },
          "performance_graph": {
            "x_label": [
              "一",
              "二",
              "三",
              "四",
              "五",
              "六",
              "日"
            ]
          }
        },
        "launch": {
          "html": "這是全新版本的每週用電總結。新版本加入了更多有用資料及改良了圖像顯示。有別於以往會整合所有地點的數據，現在每週用電總結只包含預設的地點%{location}的數據，以提供更貼近的體驗。歡迎電郵至<a href='mailto:connection@hibluesky.co'>connect@hibluesky.co</a>告訴我們您的想法。希望您喜歡！",
          "title": "全新每週用電總結"
        },
        "performance": {
          "cheer_up": {
            "do_better": "下週加油!",
            "keep_up": "下週繼續努力！"
          },
          "days_within_budget": "在上週 7 天當中，有 %{days} 天的用電量在每日能源預算之內。",
          "description": {
            "exceed_budget": "超出預算 %{percentage}",
            "within_budget": "低於預算 %{percentage}"
          },
          "foot_note": "* 能源預算是Blue Sky 人工智能基於用電記錄、外在天氣、月份及假日等一籃子因素而推算。",
          "legend": {
            "above_budget": "超過能源預算",
            "below_budget": "低於能源預算",
            "budget": "能源預算"
          },
          "title": "上週的用電狀況"
        },
        "ranking": {
          "description": "優於 %{rank_percentage} 的用戶",
          "foot_note": "* 此排名比較每個地點每平方米的用電量。",
          "position": "%{total} 用戶中的第 %{rank} 名",
          "position_change": {
            "decrease": {
              "one": "對比往週下降了 %{count} 位",
              "other": "對比往週下降了 %{count} 位"
            },
            "increase": {
              "one": "對比往週上升了 %{count} 位",
              "other": "對比往週上升了 %{count} 位"
            },
            "no_change": "對比往週排名不變"
          },
          "title": "你的排名"
        },
        "see_energy_trends": "查看用電趨勢",
        "subject": "Blue Sky - 每週總結",
        "tips": {
          "description": "針對你所在地點的最高耗電量電器",
          "title": "行動貼士"
        },
        "title": "每週用電總結"
      }
    },
    "welcome": {
      "access_web_version": "進入網頁版本",
      "greeting": {
        "checkout": "Blue Sky 現正為您身處的空間優化用電模式，<br/>務求降低耗電又減少電費支出。",
        "hi": "你好 %{name}"
      },
      "main_body": {
        "credentials": {
          "login": "登入賬號",
          "password": "密碼"
        },
        "login_description": "您可以在任何時間登入查看儀表板",
        "video_description": "看看我們如何協助您"
      },
      "subject": "Blue Sky - 歡迎您"
    }
  },
  "enumerize": {
    "location": {
      "daily_target_aggressiveness_level": {
        "aggressive": "進取地節能（預設策略減 8%）",
        "conservative": "保守地節能（預設策略減 3%）",
        "default": "預設節能策略",
        "normal": "平穩地節能（預設策略減 5%）"
      }
    },
    "user": {
      "user_type": {
        "public": "Public Display"
      }
    },
    "user_preference": {
      "language": {
        "en": "英文 English",
        "zh-TW": "繁體中文 Traditional Chinese"
      }
    }
  },
  "errors": {
    "messages": {
      "already_confirmed": "已經驗證，請直接登入。",
      "confirmation_period_expired": "必須在 %{period} 內驗證，請重新申請。",
      "expired": "已經過期，請重新申請。",
      "not_found": "找不到。",
      "not_locked": "並未被鎖定。",
      "not_saved": {
        "one": "有一個錯誤導致 %{resource} 不能被儲存：",
        "other": "有 %{count} 個錯誤導致 %{resource} 不能被儲存："
      }
    }
  },
  "first_login": {
    "hello": "Hello!",
    "intro": "Start personalizing your account!",
    "intro_popup": {
      "confirm_button": "Let's save energy!",
      "next_button": "Next"
    },
    "notification_email_hint": "This will be your login name and password recovery email going forward. Optional energy tips, fun facts and Blue Sky notifications will be sent here too.",
    "password_confirmation_label": "Confirm New Password",
    "password_confirmation_placeholder": "Enter the password again",
    "password_label": "New Password",
    "password_placeholder": "Leave blank if want to keep the original password",
    "skip_link": "Skip",
    "skip_popup": {
      "cancel_button": "Cancel",
      "confirm_button": "Skip for now",
      "message1": "By skipping, you might miss out on:<br/>1. Energy alerts & reports<br/>2. Opportunities to join fun events<br/>3. Collaborations with peers<br/>",
      "message2": "OK. If you change your mind, you can update your personal information later on PROFILE page.",
      "title": "Are you sure?"
    },
    "submit_popup": {
      "cancel_button": "Fill now",
      "confirm_button": "Continue Update",
      "message": "Notification Email is blank. This is for receiving tips on your usage, notices of fun events, and for password reset. We will not share it with 3rd party.",
      "title": "Continue?"
    },
    "update_button": "Update"
  },
  "general": {
    "15_min_up_to": "每十五分鐘更新, 截至 %{time}",
    "hourly_up_to": "每小時更新, 截至 %{time}"
  },
  "graph": {
    "all_locations": "所有地方",
    "appliance": "電器",
    "daily": "每天",
    "distribution": {
      "chart_title": "電器用電量分佈圖",
      "last_24_hours": "過往24小時"
    },
    "distribution_graph": "分佈圖表",
    "every_minute": "每分鐘",
    "hourly": "每小時",
    "iaq": {
      "co2": "二氧化碳",
      "co2_y_axis": "百萬分之",
      "cold": "寒冷",
      "dry": "乾燥",
      "fair": "良好",
      "healthy": "優異",
      "hot": "炎熱",
      "humid": "相對濕度",
      "humid_y_axis": "百分比",
      "iaq_update": "空氣數據每十五分鐘更新",
      "moderate": "中等",
      "no_data": "沒有數據",
      "optimal": "舒適",
      "pm25": "懸浮粒子2.5",
      "pm25_y_axis": "微克/立方米",
      "poor": "嚴重",
      "score": "空氣質量分數",
      "score_y_axis": "分數",
      "temp": "室內溫度",
      "temp_y_axis": "攝氏",
      "unhealthy": "甚高",
      "voc": "揮發性有機物",
      "voc_y_axis": "十億分之",
      "wet": "潮濕"
    },
    "iaq_graph": "室內空氣質量圖表",
    "interval": "時間間隔",
    "oaq": {
      "co": "一氧化氮",
      "co_unit": "百萬分之",
      "co_y_axis": "百萬分之",
      "good": "好",
      "hazardous": "危險",
      "humidity": "相對濕度",
      "humidity_unit": "百分比",
      "humidity_y_axis": "百分比",
      "loading": "載入中",
      "moderate": "中等",
      "no2": "二氧化氮",
      "no2_unit": "微克/立方米",
      "no2_update": "最後更新: %{time}",
      "no2_y_axis": "微克/立方米",
      "no_data": "暫無資料",
      "pm10": "懸浮粒子10",
      "pm10_unit": "微克/立方米",
      "pm10_y_axis": "微克/立方米",
      "pm25": "懸浮粒子2.5",
      "pm25_unit": "微克/立方米",
      "pm25_y_axis": "微克/立方米",
      "pressure": "壓力",
      "pressure_unit": "帕",
      "pressure_y_axis": "帕",
      "temperature": "溫度",
      "temperature_unit": "攝氏",
      "temperature_y_axis": "攝氏",
      "unhealthy": "不健康",
      "unhealthy_sensitive": "不適於敏感人群",
      "very_unhealthy": "非常不健康",
      "windspeed": "風速",
      "windspeed_unit": "米/秒",
      "windspeed_y_axis": "米/秒"
    },
    "oaq_graph": "Airspot圖表",
    "range": "範圍",
    "thirty_minute": "每半小時",
    "time": {
      "all_registers": "全部",
      "analytics_lines": "分析",
      "auto_update_every_minute": "* 每分鐘自動更新",
      "average": "平均",
      "change_button": "轉換",
      "daily": {
        "every_day": "篩選日子",
        "to_yesterday": "截至昨天"
      },
      "energy": "用電量",
      "energy_consumption": "電力消耗",
      "env_data": "環境數據",
      "hourly": {
        "every_hour": "篩選小時",
        "to_last_hour": "截至上小時"
      },
      "humidity": "相對濕度",
      "humidity_axis_title": "%{name} (%)",
      "last_1_hour": "過往一小時",
      "loading": "載入中...",
      "max": "最高",
      "min": "最低",
      "monthly_average": "按月平均",
      "monthly_highest": "按月最高",
      "monthly_lowest": "按月最低",
      "monthly_total": "按月總計",
      "target_hit_rate": "達標率",
      "temperature": "氣溫",
      "temperature_axis_title": "%{name} (°C)",
      "thirty_minute": {
        "to_last_half_hour": "截至上半小時"
      },
      "time_position": {
        "1080_hours_ago": "45天前",
        "168_hours_ago": "7天前",
        "2160_hours_ago": "三個月前",
        "720_hours_ago": "30天前",
        "half_year_ago": "半年前",
        "latest": "最近",
        "one_month_ago": "一個月前",
        "three_months_ago": "三個月前"
      },
      "total": "總計",
      "usage": "用量",
      "weather_source": {
        "accuweather": "氣溫及相對濕度資料來源：<a href=\"https://www.accuweather.com\" target=\"_blank\" rel=\"noopener noreferrer\">AccuWeather</a>",
        "hko": "氣溫及相對濕度資料來源：<a href=\"http://www.hko.gov.hk/contentc.htm\" target=\"_blank\" rel=\"noopener noreferrer\">香港天文台</a>"
      },
      "yaxis": "度電"
    },
    "time_graph": "能源圖表"
  },
  "green_index": {
    "annual_coming_soon": "年度大回顧即將上線，敬請期待！",
    "annual_report": "下載年度大回顧:",
    "instruction_1": "圖表總結過去一個月或一年實際用量跟綠色指數的比較。<br/>綠色指數由人工智能設置，代表單位合理的用電量。<br/>每次總結單位服務質素，不妨把用電表現加入討論。利用以下口號，方便大家研究。",
    "instruction_2": "用量遊走綠色區，用電達標無難度。<br/>用電高於綠色區，研究用電免超支。",
    "instruction_3": "圖表於每月一號更新，按以下連結可下載過往十二個月份的資料。",
    "monthly_coming_soon": "每月小總結即將上線，敬請期待！",
    "monthly_report": "下載每月小總結:"
  },
  "head_section": {
    "missing_record": {
      "description": "<h3>注意！</h3>本地點或子地點正進行維護，部份電力數據可能有偏差。不便之處，敬請見諒。"
    },
    "shared_ct": {
      "description": "你現在選擇的用量是共享的。大家一齊努力啦！"
    }
  },
  "home": {
    "appliance_status": {
      "available": "可使用",
      "average": "正常",
      "disconnected": "暫無資料",
      "high": "高用量",
      "label": "電器使用情況",
      "low": "低用量",
      "no_appliance_status": "揀選一個地點以顯示電器用量",
      "occupied": "使用中",
      "popup": {
        "average": "用量處於正常水平(跟過去4個%{weekday}作比較)",
        "high": "用量高於平均數值最少15%(跟過去4個%{weekday}作比較)",
        "low": "用量低於平均數值最少15%(跟過去4個%{weekday}作比較)"
      }
    },
    "current_location_message": "你現在位於 %{location}",
    "current_location_message_ust": "歡迎來到 %{location}",
    "performance": {
      "label": "實時表現",
      "peer_rank": "排名",
      "self_change": "上週"
    },
    "today": {
      "iaq": {
        "disconnected": "暫無資料",
        "fair": "良好",
        "healthy": "優異",
        "label": "室內空氣質素",
        "loading": "載入中",
        "moderate": "中等",
        "multiple": "以下是數個監察儀器的平均數字",
        "poor": "嚴重",
        "unhealthy": "甚高"
      },
      "left": {
        "good": "好！繼續努力",
        "high": "耗電比平常快了！嘗試改變一下吧",
        "label": "今天尚餘用電配額",
        "label_ust": "今天的剩餘能源預算",
        "ok": "正常使用中"
      },
      "oaq": {
        "disconnected": "暫無資料",
        "good": "好",
        "hazardous": "危險",
        "label": "二氧化氮數據",
        "last_update": "最後更新: %{time}",
        "loading": "載入中",
        "moderate": "中等",
        "unhealthy": "不健康",
        "unhealthy_sensitive": "不適於敏感人群",
        "very_unhealthy": "非常不健康"
      },
      "oaq_chart": {
        "2_days_ago": "兩天前",
        "label": "過去兩天的二氧化氮數據",
        "last_24_hours": "過去24小時",
        "last_update": "最後更新: %{time}",
        "loading": "載入中",
        "unit": "微克/立方米"
      },
      "used": {
        "daily_target_description": "本日目標的%{percentage}",
        "daily_target_description_ust": "本日預算的%{percentage}",
        "description": {
          "cost": "電費",
          "iphone": "iPhone",
          "telsa": "Tesla"
        },
        "label": "今天用電量",
        "times": "次"
      }
    },
    "ust_budget": {
      "budget": "能源預算",
      "energy_budget": "能源預算",
      "energy_used": "用電量",
      "kwh_to_points": "1kWh等於10點",
      "label": "總能源預算",
      "overall_points_left": "剩餘能源預算:",
      "overuse": "到目前為止，你的用電量已超出總能源預算",
      "points": "點",
      "to": "至",
      "today": "今",
      "underuse": "目前為止，你的用電量在總能源預算之內"
    },
    "ust_rolling_chart": {
      "2_days_ago": "兩天前",
      "3_days_ago": "三天前",
      "label": "過去三天的用電量",
      "last_24_hours": "過去24小時",
      "points": "點"
    }
  },
  "how_to": {
    "comparison": {
      "description": "利用自身和朋輩影響推動，日常節能添趣味。",
      "description_ust": "回看過往四週用電情況"
    },
    "daily_quota": {
      "description": "結合人工智能科技，自動為你設定最佳節能目標。"
    },
    "graph": {
      "description": "多元化數據分析，讓你深入了解用電變化，找出四季節能機會。"
    },
    "home": {
      "description": "只需花幾秒鐘，用電情況一目了然，監察用電變得簡單。"
    },
    "notification": {
      "description": "繁忙的生活令你無暇看數據？提示功能讓你掌握每個重要信息。"
    },
    "page_head": "操作指南",
    "profile": {
      "description": "定期更新個人資料，保持聯繫。"
    },
    "report": {
      "description": "下載原始用電數據，紀錄最近90日用量。設定好日期和時段，報表一按到手。"
    }
  },
  "iaq_matrix": {
    "co2": "二氧化碳",
    "co2_unit": "百萬分之",
    "humidity": "相對濕度",
    "humidity_unit": "百分比",
    "pm25": "懸浮粒子2.5",
    "pm25_unit": "微克/立方米",
    "score": "室內空氣質素",
    "score_unit": "分數",
    "temperature": "室內溫度",
    "temperature_unit": "攝氏",
    "voc": "揮發性有機物",
    "voc_unit": "十億分之"
  },
  "location": {
    "close": "關閉",
    "tab": "地點",
    "zoningplan": "分區圖"
  },
  "login": {
    "disclaimer": "如要參考個人資料收集及個人私隱聲明，請<a href=\"http://www.hibluesky.co/terms\" target=\"_blank\" rel=\"noopener noreferrer\">按此</a>。",
    "email": "電郵 （首次登入：首次登入帳號）",
    "first_name": "名字",
    "forget_password": "忘記密碼？",
    "forget_password_send_instruction": "重置密碼",
    "inactive_login": "此帳戶經已凍結",
    "last_name": "姓名",
    "log_in": "登入",
    "password": "密碼 （首次登入：激活碼）",
    "password_confirmation": "確定密碼",
    "registration_unauthorized_access": "此頁面不開放",
    "remember_me": "記住我",
    "set_password": "密碼 (最少 %{length} 個字元)",
    "sign_in_header": "Sign in to empower my energy",
    "sign_up": "註冊",
    "sign_up_header": "Sign up to empower my energy"
  },
  "menu": {
    "admin": "後台",
    "comparison": "比較",
    "daily_target": "每日目標",
    "faq": "常見問題",
    "graph": "圖表",
    "green_index": "綠色指數",
    "home": "主頁",
    "how_to": "操作指南",
    "language": "LANG. 語言",
    "lock": "螢幕上鎖",
    "log_out": "登出",
    "multimedia": "多媒體",
    "notification": "通知",
    "notifications": "通知",
    "payment": "繳費",
    "profile": "個人資料",
    "report": "報表",
    "social": "社群",
    "status": "實時情況",
    "unlock": "螢幕解鎖",
    "ust_info_page": "能源預算計劃",
    "video": "影片"
  },
  "notification": {
    "create_user": {
      "body": "來探索這儀錶板的特點",
      "title": "歡迎進入Blue Sky儀錶板"
    },
    "user_event": {
      "abnormality": {
        "faq_link": "https://ahblue.helpsite.com/",
        "graph": {
          "greenindex": "綠色指數",
          "high": "高",
          "low": "低",
          "normal": "正常"
        },
        "overuse": {
          "body_html": "昨天%{location}的用電量達%{value}kWh，比綠色指數上限高%{percent}%，研究機會來了！",
          "most_consuming": "最高用量的電器：%{appliance}",
          "title": "咦，昨天的用電量偏高！"
        },
        "underuse": {
          "body_html": "昨天%{location}的用電量為%{value}kWh，比綠色指數下限低%{percent}%！",
          "title": "昨天的用電量偏低，單位節能表現佳！"
        }
      },
      "about_target_alert": {
        "body_html": "你今天的電力對比是日目標還剩少於20%，來查看以下地點是否正常？<div>%{locations}</div>",
        "title": "噢！看來你使用電力比平時多啊。"
      },
      "aqhi_alert": {
        "body_html": "預測%{location_name}今日的室外空氣健康風險將會達至%{level}級別。%{oaq_advice}<br/>室外空氣污染物可能會透過門窗進入室內，當空氣污染嚴重時，可關上窗戶、開啟空氣淨化設備和有過濾作用的抽風系統，以減少空氣污染物對健康的影響。<br/><br/>室外空氣健康風險提示由Blue Sky及PRAISE-HK提供",
        "high": "高",
        "serious": "嚴重",
        "title": "%{location_name}今日的室外空氣健康風險可能達至%{level}級別",
        "very_high": "甚高"
      },
      "baseload_comparison_alert": {
        "body_html": "有些電器在%{hours}時比過去30天平均花費多%{percent}%。你知道原因嗎？",
        "title": "昨晚是否有電器忘記關上？"
      },
      "budget_success_rate": {
        "body_html": "在上月%{total_days}天當中%{success_days}天達到了每日能源預算（達標率%{rate}%）。請按<a href=\"http://green.ust.hk/en-us/energyquotaexp/\">連結</a>以了解更多節能方法。",
        "title": "上月的能源預算達標率： %{rate}%"
      },
      "can": {
        "blackspot": {
          "body_html": "Your place has an average %{percent}% of NO2 higher than the city average! Please contact the nearby district councillor to reflect the pollution issue.",
          "title": "You are at a pollution blackspot!!"
        },
        "clean_air_spot": {
          "body_html": "Your place was a clean air spot and was %{percent}% lower than the WHO standard.",
          "title": "This was the Clean Air Spot of the last month"
        },
        "health_hazard": {
          "body_html": "The current NO2 concentration at %{time} is much higher than the safety level. Is it because of the nearby heavy traffic? Let's make sure you are not exposed in the area for a long period of time.",
          "title": "Health hazard warning"
        }
      },
      "daily_socket_comparison_alert": {
        "body_html": "凌晨三時，你的插座用了比起昨晨十一時多%{percent}%電力。希望不會是加班工作吧！<br/>來看看儀錶板的圖表找更多資訊。",
        "title": "夜闌人靜，凌晨時插座的電力竟然比日頭用多%{percent}%！"
      },
      "daily_usage_comparison_alert": {
        "body_html": "昨天的全日用電量比過去30天平均多%{percent}％以上，最大升幅類別為%{register}，來看看圖表找原因吧！",
        "title": "咦，昨天的用電量增多了%{percent}%。"
      },
      "daily_usage_comparison_celebration": {
        "body_html": "你的區域用電比過去30天平均少%{percent}%，最大減幅類別為%{register}，分享你的努力及貼士吧！",
        "title": "做得好啊，你昨日的用電量比之前少%{percent}%！"
      },
      "exceed_x_percent": {
        "body_html": "你已用完能源預算的%{percent}％。如果繼續維持當前的能源消耗率，預計你會在%{date}超出預算。該計劃將於11/30結束，繼續加油省電以獲得獎品！",
        "title": "你已用完總能源預算的%{percent}％"
      },
      "excess_co2_alert": {
        "action_tip": "<br/><br/>行動貼士: %{action_tip}",
        "body_html": "在過去一個小時，你在%{location_name}的二氧化碳濃度過高。過高的二氧化碳濃度會令你昏昏欲睡、生產力下降，根據研究顯示甚至會降低50%認知能力。",
        "title": "%{location_name}的二氧化碳濃度過高"
      },
      "excess_pm25_alert": {
        "action_tip": "<br/><br/>行動貼士: %{action_tip}",
        "body_html": "在過去一個小時，你在 %{location_name} 的懸浮粒子 (PM2.5) 濃度異常地高。<br/><br/>短時間暴露於高濃度的懸浮粒子(PM2.5)會引致咳嗽和呼吸困難，而長時間暴露與高濃度懸浮粒子（PM2.5)則會增加心臟病和肺癌的死亡風險。",
        "title": "%{location_name}的懸浮粒子(PM2.5)濃度很高"
      },
      "excess_voc_alert": {
        "action_tip": "<br/><br/>行動貼士: %{action_tip}",
        "body_html": "在過去一個小時， 你在%{location_name}的揮發性有機化合物濃度異常地高。 過多的揮發性有機化合物會影響你的認知功能，例如解決問題能力和決策能力。 ",
        "title": "%{location_name}的揮發性有機化合物濃度很高"
      },
      "humidity_alert": {
        "high": {
          "action_tip": "<br/><br/>行動貼士: %{action_tip}",
          "body_html": "在過去一個小時，你在%{location_name}的濕度高於70%。過度潮濕的空氣會增加霉菌的生長，甚至導致塵蟎、細菌及某些病毒的活性增強。",
          "title": "%{location_name}的濕度過高"
        },
        "low": {
          "action_tip": "<br/><br/>行動貼士: %{action_tip}",
          "body_html": "在過去一個小時，你在%{location_name}的濕度低於40%。過度乾燥的空氣會刺激你的眼睛，引起鼻塞，加劇過敏，甚至導致某些病毒的活性增強。",
          "title": "%{location_name}的濕度過低"
        }
      },
      "iaq_score_alert": {
        "body_html": "您今天的室內空氣質量指數是%{score} (%{level})。通過改善通風來提高您的空氣品質。",
        "title": "%{location_name}的室內空氣質量：%{level}"
      },
      "meter_offline": {
        "location_missed": "%{location} (斷線了%{missing_days}天)",
        "note": "哎唷，以上位置的智能電錶斷了線，我們暫時未能顯示實時用電數據。請你到電錶房找Blue Sky的白色箱，旁邊有一個水氣開關掣，把它關掉10秒後重新開啟。( 某些單位需要換上預先提供的後備插座）簡單如此。有疑問？馬上聯絡你的藍天職員。",
        "title": "智能電錶斷了線"
      },
      "monthly_budget": {
        "exceed": {
          "body_html": "哎呀！你%{month}的用電量超過你的能源預算。從現在開始，積極節能減碳，以獲取獎品並避免付冷氣費用。",
          "title": "你超出了%{month}的能源預算"
        },
        "within": {
          "body_html": "你上個月的用電量低於能源預算，繼續努力來獲得獎勵：太陽能能源產品，風扇和其他好東西！",
          "title": "你%{month}的用電量在能源預算之內"
        }
      },
      "office_hour_comparison_alert": {
        "body_html": "智能電錶偵測到%{hour} 的用電量比過去30天高超過%{percent}% 而最大升幅類別為%{register}。<br/> 是否曾經進行特別活動？來看看過去用量吧！",
        "title": "哇！剛剛一小時用電量比平時多%{percent}%以上了。"
      },
      "office_hour_comparison_celebration": {
        "body_html": "你一定做了一些事幫助節省能源，因為你的用電量於%{hour} 比過去30天平均少了%{percent}%！ 最大減幅類別為%{register}<br/> 分享一下改變了甚麼，並繼續加油！",
        "title": "做得好呀！你比之前用電少%{percent}%了！"
      },
      "overall_exceed_budget": {
        "body_html": "哎呀！你的用電量已經超出了整個學期的能源預算。你將必須償還整個學期的冷氣費用，請盡量省電，以避免多付錢。",
        "title": "你已超出總能源預算 - 從現在開始收費"
      },
      "overuse_appliance": {
        "action_tip": "<br/><br/>提示：%{action_tip}",
        "body_html": "<b>今天%{start_hour} 至%{end_hour}，%{location}的%{register}用電量過高。</b>",
        "title": "%{location} %{register}用電量過高"
      },
      "peer_comparison": {
        "action_tip": "<br/><br/>小貼士: %{action_tip}",
        "low": {
          "body_html": "<b>你上月的用電量高於%{parent_location_name}的八成用家用量。</b><br/><br/>發電佔本港碳排放的67%。保衛藍天，齊減碳排放！",
          "title": "%{location_name}上月的用電量高於%{parent_location_name}的大部份用戶。"
        },
        "middle": {
          "body_html": "<b>%{peer_count}名用戶當中，你上個月排第%{rank}名</b><br/><br/>發電佔本港碳排放的67%。保衛藍天，齊減碳排放！",
          "title": "節能排名 - %{peer_count}名用戶中你排第%{rank}"
        },
        "top": {
          "body_html_1st": "<b>恭喜, 你上個月排第一名啊！</b><br/><br/>上月，%{peer_count}名用戶當中你排第%{rank}，成功減排 %{kg_of_co2} 公斤 二氧化碳。",
          "body_html_2nd": "<b>恭喜, 你上個月排第二名啊！</b><br/><br/>上月，%{peer_count}名用戶當中你排第%{rank}，成功減排 %{kg_of_co2} 公斤 二氧化碳。",
          "body_html_3rd": "<b>恭喜, 你上個月排第三名啊！</b><br/><br/>上月，%{peer_count}名用戶當中你排第%{rank}，成功減排 %{kg_of_co2} 公斤 二氧化碳。",
          "title": "節能排名 - %{peer_count}名用戶中你排第%{rank}"
        }
      },
      "temperature_alert": {
        "high": {
          "action_tip": "<br/><br/>行動貼士: %{action_tip}",
          "body_html": "在過去一個小時，%{location_name}的溫度高於30°C。室內溫度過高有機會引致暈眩或頭痛等症狀。",
          "title": "%{location_name}的溫度過高"
        },
        "low": {
          "action_tip": "<br/><br/>行動貼士: %{action_tip}",
          "body_html": "在過去一個小時，%{location_name}的溫度低於12°C。太冷的環境會降低舒適度。",
          "title": "%{location_name}的溫度過低"
        }
      },
      "update_target_reminder": {
        "body": "看來你未準備下個月的目標。是時候了，按這兒來設定目標！",
        "title": "下個月的目標，一起來未雨綢繆吧！"
      },
      "weather_notification": {
        "title": "明天要留意以下貼士"
      },
      "weekly_budget": {
        "exceed": {
          "action_tips": "<br/><br/>提示：<br/>1. %{action_tip_1}<br/>2. %{action_tip_2}",
          "body_html": "哎呀！由於%{appliance}的用電量過多，你超出了上週的能源預算。繼續努力省電，以避免付冷氣費用。",
          "title": "你超出了上週的能源預算"
        },
        "within": {
          "body_html": "你上星期的用電量低於能源預算。保持良好的表現，以在學期末獲得獎勵，其中包括太陽能行動電源，風扇和其他好東西！",
          "title": "你上週的用電量在能源預算之內"
        }
      }
    }
  },
  "notification_group": {
    "abnormality": {
      "description": "發現異常情況時作出提示",
      "name": "綠色指數提示"
    },
    "bluesky": {
      "description": "來自 Blue Sky 的消息（偶爾傳送）",
      "name": "Blue Sky 訊息"
    },
    "calendar": {
      "description": "為特別日子（如假日或地球日）預先作出安排",
      "name": "特別日子提示"
    },
    "can": {
      "description": "室外空氣質素提示",
      "name": "健康空氣行動相關訊息"
    },
    "energy_use_status": {
      "description": "每日狀況報告",
      "name": "用電量速報"
    },
    "fun_energy_fact": {
      "description": "知多一點點（偶爾傳送）",
      "name": "趣味資訊"
    },
    "hku": {
      "description": "有關HKU的訊息",
      "name": "HKU 相關訊息"
    },
    "iaq": {
      "description": "發現室內空氣不健康時作出提示",
      "name": "室內空氣質素（IAQ）提示"
    },
    "meter_offline": {
      "description": "如遇問題如斷了線便會發出通知",
      "name": "智能電錶維護"
    },
    "oaq": {
      "description": "發現室外空氣不健康時作出提示",
      "name": "室外空氣質素（OAQ）提示"
    },
    "overuse_appliance": {
      "description": "發現電器用量異常時作出提示",
      "name": "電器用電量過高提示"
    },
    "peer_comparison": {
      "description": "每月節能排名提示",
      "name": "節能排名提示"
    },
    "ust_budget": {
      "description": "energy budget scheme updates",
      "name": "HKUST Budget Scheme Related"
    },
    "weekly_summary": {
      "description": "每週分析報告",
      "name": "每週總結"
    }
  },
  "oaq_matrix": {
    "co": "一氧化碳",
    "co_unit": "百萬分之",
    "humidity": "相對濕度",
    "humidity_unit": "百分比",
    "latitude": "緯度",
    "latitude_unit": "°",
    "logitude": "經度",
    "logitude_unit": "°",
    "no2": "二氧化氮",
    "no2_unit": "微克/立方米",
    "pm10": "懸浮粒子10",
    "pm10_unit": "微克/立方米",
    "pm25": "懸浮粒子2.5",
    "pm25_unit": "微克/立方米",
    "pressure": "壓力",
    "pressure_unit": "帕",
    "temperature": "溫度",
    "temperature_unit": "攝氏",
    "windspeed": "風速",
    "windspeed_unit": "米/秒"
  },
  "payment": {
    "content": "冷氣費用 = $ %{fee}",
    "pay_now": "立刻繳費",
    "title": "%{location}的繳費項目"
  },
  "profile": {
    "current_password_hint": "必須要以作任何更改",
    "login_cannot_change": "<i class=\"fa fa-lock\"></i> 不能更改",
    "password_again": "重覆輸入密碼",
    "password_leave_blank": "如不想更改密碼，可保留空白",
    "section": {
      "basic_information": "基本資料",
      "change_password": "更改密碼",
      "data_driven_alert": "數據驅動通知",
      "latest_news": "最新動態",
      "notification": "通知",
      "save_changes": "儲存變更"
    },
    "update_button": "更改"
  },
  "report": {
    "all_locations": "所有地點",
    "appliance_category": "電器種類",
    "at": "時間 (日期)",
    "at_dow": "時間 (星期)",
    "at_hour": "時間 (小時)",
    "daily": "每天",
    "daily_quota": "每日目標",
    "date_range": "日期範圍",
    "device_name": "儀器名稱",
    "energy_data": "電量數據",
    "export": "下載",
    "export_format": "輸出檔案格式",
    "fifteen_minute": "每十五分鐘",
    "group_by_appliance": "以電器分類",
    "hourly": "每小時",
    "humidity": "相對濕度",
    "iaq_data": "室內空氣質素數據",
    "in_on_peak": "是",
    "include_children?": "包括其下地點？",
    "interval": "時間間隔",
    "location": "地點",
    "location_description": "地點備註",
    "meet_daily_quota": "是否達標？",
    "meet_quota": "達標",
    "miss_quota": "不達標",
    "not_in_on_peak": "否",
    "oaq_data": "室外空氣質素數據",
    "on_peak": "高峰期？",
    "output_format": "匯出格式",
    "path": "地點路徑",
    "range-note-daily": "可以下載最多 90 天數據",
    "range-note-fifteen_minute": "可以下載最多 60 天數據",
    "range-note-hourly": "可以下載最多 45 天數據",
    "range-note-thirty_minute": "可以下載最多 45 天數據",
    "raw": "原始格式",
    "register_name": "名稱",
    "register_value": "數值",
    "temperature": "氣溫",
    "ten_minute": "每十分鐘",
    "thirty_minute": "每半小時",
    "time_range": "時間範圍",
    "total": "總用量",
    "unit": "單位",
    "week": "每週"
  },
  "select_location": {
    "all": "所有地點",
    "label": "選擇地點",
    "search": "搜尋"
  },
  "time": {
    "formats": {
      "daily_report": "%Y-%m-%d",
      "hourly_graph": "%b%-d日 %H:%M",
      "hourly_report": "%Y-%m-%d %H:%S",
      "self_comparison": "%d/%m",
      "today_daily_use": "%a"
    }
  },
  "user_notification_preference": {
    "confirm_reset": "確定重設？",
    "email": "電郵通知",
    "enable": "開啟",
    "push": "電話推送",
    "reset_original": "重設回原狀"
  },
  "user_target": {
    "choose_a_location": "請選擇地點",
    "create_button": "設定",
    "create_succeed": "目標已設定",
    "current_target": "現在目標",
    "desktop_for_configuration": "請用桌面電腦進行「每日目標」設定",
    "destroy_succeed": "目標已移除",
    "energy_use_kVA": "功率 (kVA)",
    "energy_use_kVAh": "度電 (kVAh)",
    "energy_use_kW": "功率 (kW)",
    "energy_use_kWh": "度電 (kWh)",
    "more_history": "更多歷史",
    "new_user_target": "設定目標",
    "no_target": "尚未設定",
    "past_average_1month": "過往平均值<br/>（1個月）",
    "past_average_3month": "過往平均值<br/>（3個月）",
    "reduction": "我想比過去 %{month} 個月減",
    "report": {
      "effective?": "已生效？",
      "effective_on": "生效日",
      "include_history": "包括歷史目標？",
      "location": "地點",
      "location_description": "地點備註",
      "n": "否",
      "set?": "已設定？",
      "y": "是"
    },
    "set_new_target": "設定新目標？",
    "targets": "歷史目標",
    "title": "自訂目標",
    "vs": "新目標比過去 %{month} 個月"
  },
  "video": {
    "audio": "想欣賞歌曲？按此音效鍵吧！",
    "countdown": "影片將會於 ",
    "finish": "多謝收看！",
    "seconds": " 秒後播放"
  },
  "will_paginate": {
    "next_label": "下一頁",
    "page_entries_info": {
      "multi_page": "正列出 %{model} 的第 %{from} 到第 %{to} 筆資料 (共 %{count} 筆資料)",
      "multi_page_html": "正列出 %{model} 的第 <strong>%{from}</strong> 到第 <strong>%{to}</strong> 筆資料 (共 <strong>%{count}</strong> 筆資料)",
      "single_page": {
        "one": "正列出 1 則 %{model} 資料",
        "other": "正列出所有共 %{count} 筆的 %{model} 資料",
        "zero": "暫無任何 %{model} 的資料"
      },
      "single_page_html": {
        "one": "正列出 <strong>1</strong> 筆 %{model} 資料",
        "other": "正列出所有共 <strong>%{count}</strong> 筆的 %{model} 資料",
        "zero": "暫無任何 %{model} 的資料"
      }
    },
    "page_gap": "…",
    "previous_label": "上一頁"
  }
});
