/*
File generated by js-routes 1.4.4
Based on Rails routes of Greent::Application
 */

(function() {
  var DeprecatedBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, Utils, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  function ParameterMissing(message, fileName, lineNumber) {
    var instance = new Error(message, fileName, lineNumber);
    if(Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  }

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf){
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  SpecialOptionsKey = "_options";

  DeprecatedBehavior = false;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = DeprecatedBehavior || actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          if (value != null) {
            delete parameters[left];
            return this.path_identifier(value);
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, "") !== left) {
        route[1] = left = left.replace(/^\*/i, "");
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace.split(".");
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// admin_health_check_index => /:locale/admin/health_check(.:format)
  // function(locale, options)
  admin_health_check_index_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"health_check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_index => /:locale/admin(.:format)
  // function(locale, options)
  admin_index_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_location => /:locale/admin/locations/:id(.:format)
  // function(locale, id, options)
  admin_location_path: Utils.route([["locale",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_locations => /:locale/admin/locations(.:format)
  // function(locale, options)
  admin_locations_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_missing_records => /:locale/admin/missing_records(.:format)
  // function(locale, options)
  admin_missing_records_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"missing_records",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_reports => /:locale/admin/reports(.:format)
  // function(locale, options)
  admin_reports_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_rule => /:locale/admin/rules/:id(.:format)
  // function(locale, id, options)
  admin_rule_path: Utils.route([["locale",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"rules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_rules => /:locale/admin/rules(.:format)
  // function(locale, options)
  admin_rules_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_user => /:locale/admin/users/:id(.:format)
  // function(locale, id, options)
  admin_user_path: Utils.route([["locale",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// admin_users => /:locale/admin/users(.:format)
  // function(locale, options)
  admin_users_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_base => /
  // function(options)
  api_base_path: Utils.route([], {}, [7,"/",false]),
// change_aggressiveness_daily_targets => /:locale/locations/:location_id/daily_targets/change_aggressiveness(.:format)
  // function(locale, location_id, options)
  change_aggressiveness_daily_targets_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"daily_targets",false],[2,[7,"/",false],[2,[6,"change_aggressiveness",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// change_option_daily_targets => /:locale/locations/:location_id/daily_targets/change_option(.:format)
  // function(locale, location_id, options)
  change_option_daily_targets_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"daily_targets",false],[2,[7,"/",false],[2,[6,"change_option",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// comparison_index => /:locale/locations/:location_id/comparison(.:format)
  // function(locale, location_id, options)
  comparison_index_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"comparison",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// confirmation_resend_first_login_index => /:locale/first_login/confirmation_resend(.:format)
  // function(locale, options)
  confirmation_resend_first_login_index_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"first_login",false],[2,[7,"/",false],[2,[6,"confirmation_resend",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// confirmation_sent_first_login_index => /:locale/first_login/confirmation_sent(.:format)
  // function(locale, options)
  confirmation_sent_first_login_index_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"first_login",false],[2,[7,"/",false],[2,[6,"confirmation_sent",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// create_v1_confirmation => /:locale/u/confirmation(.:format)
  // function(locale, options)
  create_v1_confirmation_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// daily_targets => /:locale/locations/:location_id/daily_targets(.:format)
  // function(locale, location_id, options)
  daily_targets_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"daily_targets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// distribution_data_graphs => /:locale/locations/:location_id/graphs/distribution_data(.:format)
  // function(locale, location_id, options)
  distribution_data_graphs_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"graphs",false],[2,[7,"/",false],[2,[6,"distribution_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// download_green_index_index => /:locale/locations/:location_id/green_index/download(.:format)
  // function(locale, location_id, options)
  download_green_index_index_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"green_index",false],[2,[7,"/",false],[2,[6,"download",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_admin_location => /:locale/admin/locations/:id/edit(.:format)
  // function(locale, id, options)
  edit_admin_location_path: Utils.route([["locale",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_admin_rule => /:locale/admin/rules/:id/edit(.:format)
  // function(locale, id, options)
  edit_admin_rule_path: Utils.route([["locale",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_admin_user => /:locale/admin/users/:id/edit(.:format)
  // function(locale, id, options)
  edit_admin_user_path: Utils.route([["locale",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_oauth_application => /oauth/applications/:id/edit(.:format)
  // function(id, options)
  edit_oauth_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_v1_password => /:locale/u/password/edit(.:format)
  // function(locale, options)
  edit_v1_password_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_v1_profile => /:locale/u/profile(.:format)
  // function(locale, options)
  edit_v1_profile_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// energy_comparison_data => /:locale/locations/:location_id/energy_comparison_data/:range_first/to/:range_last(.:format)
  // function(locale, location_id, range_first, range_last, options)
  energy_comparison_data_path: Utils.route([["locale",true],["location_id",true],["range_first",true],["range_last",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"energy_comparison_data",false],[2,[7,"/",false],[2,[3,"range_first",false],[2,[7,"/",false],[2,[6,"to",false],[2,[7,"/",false],[2,[3,"range_last",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// faq_index => /:locale/faq(.:format)
  // function(locale, options)
  faq_index_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"faq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// first_login => /:locale/first_login/:id(.:format)
  // function(locale, id, options)
  first_login_path: Utils.route([["locale",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"first_login",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// first_login_index => /:locale/first_login(.:format)
  // function(locale, options)
  first_login_index_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"first_login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// graphs => /:locale/locations/:location_id/graphs(.:format)
  // function(locale, location_id, options)
  graphs_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"graphs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// green_index_index => /:locale/locations/:location_id/green_index(.:format)
  // function(locale, location_id, options)
  green_index_index_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"green_index",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// home => /:locale/locations/:location_id(.:format)
  // function(locale, location_id, options)
  home_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// home_tree => /:locale/home/tree(.:format)
  // function(locale, options)
  home_tree_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"home",false],[2,[7,"/",false],[2,[6,"tree",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// how_to_index => /:locale/how_to(.:format)
  // function(locale, options)
  how_to_index_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"how_to",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// iaq_comparison_data => /:locale/locations/:location_id/iaq_comparison_data/:range_first/to/:range_last(.:format)
  // function(locale, location_id, range_first, range_last, options)
  iaq_comparison_data_path: Utils.route([["locale",true],["location_id",true],["range_first",true],["range_last",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"iaq_comparison_data",false],[2,[7,"/",false],[2,[3,"range_first",false],[2,[7,"/",false],[2,[6,"to",false],[2,[7,"/",false],[2,[3,"range_last",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// iaq_data_graphs => /:locale/locations/:location_id/graphs/:graph_type/:interval/iaq_data(.:format)
  // function(locale, location_id, graph_type, interval, options)
  iaq_data_graphs_path: Utils.route([["locale",true],["location_id",true],["graph_type",true],["interval",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"graphs",false],[2,[7,"/",false],[2,[3,"graph_type",false],[2,[7,"/",false],[2,[3,"interval",false],[2,[7,"/",false],[2,[6,"iaq_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// iaq_score_home => /:locale/locations/:location_id/iaq_score(.:format)
  // function(locale, location_id, options)
  iaq_score_home_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"iaq_score",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// location_path => /:locale/locations/:location_id/path(.:format)
  // function(locale, location_id, options)
  location_path_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"path",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// mobile_notification_token => /:locale/mobile_notification_token(.:format)
  // function(locale, options)
  mobile_notification_token_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"mobile_notification_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// native_oauth_authorization => /oauth/authorize/native(.:format)
  // function(options)
  native_oauth_authorization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[2,[7,"/",false],[2,[6,"native",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_location => /:locale/admin/locations/new(.:format)
  // function(locale, options)
  new_admin_location_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_admin_rule => /:locale/admin/rules/new(.:format)
  // function(locale, options)
  new_admin_rule_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"rules",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_admin_user => /:locale/admin/users/new(.:format)
  // function(locale, options)
  new_admin_user_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_oauth_application => /oauth/applications/new(.:format)
  // function(options)
  new_oauth_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_target_template => /:locale/locations/:location_id/user_target_templates/new(.:format)
  // function(locale, location_id, options)
  new_user_target_template_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"user_target_templates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_v1_confirmation => /:locale/u/confirmation(.:format)
  // function(locale, options)
  new_v1_confirmation_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_v1_password => /:locale/u/password(.:format)
  // function(locale, options)
  new_v1_password_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_v1_registration => /:locale/u/sign_up(.:format)
  // function(locale, options)
  new_v1_registration_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// notification_feedback_index => /:locale/notification/feedback(.:format)
  // function(locale, options)
  notification_feedback_index_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"notification",false],[2,[7,"/",false],[2,[6,"feedback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// notifications => /:locale/notifications(.:format)
  // function(locale, options)
  notifications_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oaq_comparison_data => /:locale/locations/:location_id/oaq_comparison_data/:range_first/to/:range_last(.:format)
  // function(locale, location_id, range_first, range_last, options)
  oaq_comparison_data_path: Utils.route([["locale",true],["location_id",true],["range_first",true],["range_last",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"oaq_comparison_data",false],[2,[7,"/",false],[2,[3,"range_first",false],[2,[7,"/",false],[2,[6,"to",false],[2,[7,"/",false],[2,[3,"range_last",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// oaq_data_graphs => /:locale/locations/:location_id/graphs/:graph_type/:interval/oaq_data(.:format)
  // function(locale, location_id, graph_type, interval, options)
  oaq_data_graphs_path: Utils.route([["locale",true],["location_id",true],["graph_type",true],["interval",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"graphs",false],[2,[7,"/",false],[2,[3,"graph_type",false],[2,[7,"/",false],[2,[3,"interval",false],[2,[7,"/",false],[2,[6,"oaq_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// oaq_home => /:locale/locations/:location_id/oaq(.:format)
  // function(locale, location_id, options)
  oaq_home_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"oaq",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// oauth_application => /oauth/applications/:id(.:format)
  // function(id, options)
  oauth_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_applications => /oauth/applications(.:format)
  // function(options)
  oauth_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_authorization => /oauth/authorize(.:format)
  // function(options)
  oauth_authorization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_authorized_application => /oauth/authorized_applications/:id(.:format)
  // function(id, options)
  oauth_authorized_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_authorized_applications => /oauth/authorized_applications(.:format)
  // function(options)
  oauth_authorized_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_introspect => /oauth/introspect(.:format)
  // function(options)
  oauth_introspect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"introspect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_revoke => /oauth/revoke(.:format)
  // function(options)
  oauth_revoke_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"revoke",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_token => /oauth/token(.:format)
  // function(options)
  oauth_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_token_info => /oauth/token/info(.:format)
  // function(options)
  oauth_token_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// on_off_status_home => /:locale/locations/:location_id/on_off_status(.:format)
  // function(locale, location_id, options)
  on_off_status_home_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"on_off_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// power_home => /:locale/locations/:location_id/power(.:format)
  // function(locale, location_id, options)
  power_home_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"power",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// read_notification => /:locale/notifications/:id/read(.:format)
  // function(locale, id, options)
  read_notification_path: Utils.route([["locale",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"read",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// read_notifications => /:locale/notifications/read(.:format)
  // function(locale, options)
  read_notifications_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"read",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// reports => /:locale/reports(.:format)
  // function(locale, options)
  reports_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reset_admin_user => /:locale/admin/users/:id/reset(.:format)
  // function(locale, id, options)
  reset_admin_user_path: Utils.route([["locale",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// screen_lock => /:locale/screen/lock(.:format)
  // function(locale, options)
  screen_lock_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"screen",false],[2,[7,"/",false],[2,[6,"lock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// screen_unlock => /:locale/screen/unlock(.:format)
  // function(locale, options)
  screen_unlock_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"screen",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sidekiq_web => /:locale/sidekiq
  // function(locale, options)
  sidekiq_web_path: Utils.route([["locale",true]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[6,"sidekiq",false]]]]),
// time_data_graphs => /:locale/locations/:location_id/graphs/:graph_type/:interval/data(.:format)
  // function(locale, location_id, graph_type, interval, options)
  time_data_graphs_path: Utils.route([["locale",true],["location_id",true],["graph_type",true],["interval",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"graphs",false],[2,[7,"/",false],[2,[3,"graph_type",false],[2,[7,"/",false],[2,[3,"interval",false],[2,[7,"/",false],[2,[6,"data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// time_data_latest_graphs => /:locale/locations/:location_id/graphs/:graph_type/:interval/latest(.:format)
  // function(locale, location_id, graph_type, interval, options)
  time_data_latest_graphs_path: Utils.route([["locale",true],["location_id",true],["graph_type",true],["interval",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"graphs",false],[2,[7,"/",false],[2,[3,"graph_type",false],[2,[7,"/",false],[2,[3,"interval",false],[2,[7,"/",false],[2,[6,"latest",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// today_home => /:locale/locations/:location_id/today(.:format)
  // function(locale, location_id, options)
  today_home_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"today",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// today_ust_budget => /:locale/locations/:location_id/ust_budget(.:format)
  // function(locale, location_id, options)
  today_ust_budget_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"ust_budget",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// u => /:locale/u(.:format)
  // function(locale, options)
  u_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// unread_count_notifications => /:locale/notifications/unread_count(.:format)
  // function(locale, options)
  unread_count_notifications_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"notifications",false],[2,[7,"/",false],[2,[6,"unread_count",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_v1_password => /:locale/u/password(.:format)
  // function(locale, options)
  update_v1_password_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_target_template => /:locale/locations/:location_id/user_target_templates/:id(.:format)
  // function(locale, location_id, id, options)
  user_target_template_path: Utils.route([["locale",true],["location_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"user_target_templates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// user_target_templates => /:locale/locations/:location_id/user_target_templates(.:format)
  // function(locale, location_id, options)
  user_target_templates_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"user_target_templates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// ust_budget_until => /:locale/locations/:location_id/ust_budget_until/:target_date(.:format)
  // function(locale, location_id, target_date, options)
  ust_budget_until_path: Utils.route([["locale",true],["location_id",true],["target_date",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"ust_budget_until",false],[2,[7,"/",false],[2,[3,"target_date",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// ust_public => /:locale/locations/:location_id/public(.:format)
  // function(locale, location_id, options)
  ust_public_path: Utils.route([["locale",true],["location_id",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"locations",false],[2,[7,"/",false],[2,[3,"location_id",false],[2,[7,"/",false],[2,[6,"public",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v1_confirmation => /:locale/u/confirmation/show(.:format)
  // function(locale, options)
  v1_confirmation_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"show",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// v1_password => /:locale/u/password(.:format)
  // function(locale, options)
  v1_password_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v1_profile => /:locale/u/profile(.:format)
  // function(locale, options)
  v1_profile_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v1_registration => /:locale/u/sign_up(.:format)
  // function(locale, options)
  v1_registration_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v1_root => /:locale(.:format)
  // function(locale, options)
  v1_root_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// v1_session => /:locale/u/sign_in(.:format)
  // function(locale, options)
  v1_session_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v1_sign_in => /:locale/u/sign_in(.:format)
  // function(locale, options)
  v1_sign_in_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// v1_sign_out => /:locale/u/sign_out(.:format)
  // function(locale, options)
  v1_sign_out_path: Utils.route([["locale",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"locale",false],[2,[7,"/",false],[2,[6,"u",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return Utils.make();
    });
  } else {
    Utils.make();
  }

}).call(this);
